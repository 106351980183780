import React,{useState} from "react";
import Popup from 'reactjs-popup';
import {
	Routes,
	Route,
	NavLink,
	HashRouter,
	useNavigate
} from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from "../../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../../Assets/conference-user.svg";
import Conference_user_remove from "../../../Assets/conference-user-remove.svg";
import Two_user from "../../../Assets/two_user.svg";
import Two_userwhite from "../../../Assets/two-user-white.svg";
import Multiple_userwhite from "../../../Assets/multiple-user-white.svg";
import Interpreter_profile from "../../../Assets/interpreter-profile.svg";
import {ReactComponent as Iocn_Notconnect_interpreter}  from "../../../Assets/notconnect-interpreter-girl.svg";
import {ReactComponent as Iocn_Callback} from "../../../Assets/callback.svg";
import {ReactComponent as Iocn_Cancel}  from "../../../Assets/cancel.svg";
import {ReactComponent as Iocn_Cancel_call}  from "../../../Assets/cancel-call.svg";
import {ReactComponent as Icon_whitevideocall} from "../../../Assets/white-video-call.svg";
import {ReactComponent as Icon_teluguicon} from "../../../Assets/telugu-icon.svg";
import {ReactComponent as Icon_time} from "../../../Assets/time.svg";
import {ReactComponent as Icon_crossbordercancel } from "../../../Assets/cross-border-cancel.svg";
import {getprofile,getAuth} from '../../../Service/Helper';

const NotconnectedCall = (props) => {
	const authdata=getAuth()
	const userid=authdata['_id'];
	const navigate =useNavigate()
	function callbackrequest(){
		navigate('/Home')
	}
	return (
		   
				<div className="page-main">
				<div className="processing-call-tab-content">
				 <Box className="not_connect_call">
					<div className="processing-call-tab-inner">
					   <div className="calling-names-dash">
					      <div className="inte-profile">
	                         <img src={Interpreter_profile} />
					      </div>
					      <div className="inte-profile-details">
					         <span>{authdata.first_name+" "+authdata.last_name}</span>
					         {/* <div>
					            <span className="connecting_person">{props.selectedmember.member.length} <img src={Multiple_userwhite} /></span>
					            <span className="connecting_person_names"><img src={Two_userwhite} /> {props.selectedmember.contactdetails[0].contact_name}{props.selectedmember.member.length > 1 ? '+' : ''}{props.selectedmember.member.length > 1 ?  props.selectedmember.member.length - 1 : ''}</span>
					         </div> */}
					      </div>
					   </div>
					   <div className="connect-interpreter">
					      <Iocn_Cancel_call />
					      <Typography className="not_connect">Couldn’t connect with an interpreter</Typography>
					      <div className="svg_girl">
						      <Iocn_Notconnect_interpreter className="Connecting_interpreter" />
						      <Icon_crossbordercancel  className="cross"/>
					      </div>
					      <div className="two_buttons">
					       <Popup className="processingcall-modal"
						    trigger={<Button className="primary" variant="text"  ><Iocn_Callback />Request Callback</Button>
						       }
								    modal
								    nested
								  >
								    {close => (
								      <div className="modal interpreter">
								        <div className="header"> We will connect you to an
		                                    interpreter soon. </div>
								        <div className="content special-svg">
								          {' '}
								            <Icon_time />
								        </div>
								        <div className="actions">
								          <Button className="primary" onClick={()=>callbackrequest()}>Ok</Button>
								        </div>
								      </div>
								    )}
							</Popup>
					       <div className="dismiss" onClick={()=>callbackrequest()}>Dismiss</div>
					      </div>
					   </div>
					</div>
					</Box>
				</div>
				</div>
		
		)	
}

export default NotconnectedCall;