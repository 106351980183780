import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import web from "../../Assets/Vector.png";
import { ReactComponent as Icon_closegrey } from "../../Assets/close-grey.svg";
import { postDataFromApi, getDataFromApi } from "../../Service/CommonService";
import {
  HashRouter as Router,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import OtpTimer from "otp-timer";
import style from "../../Assets/css/style.css";
import Popup from "reactjs-popup";
import moment from "moment";
import swal from "sweetalert";
const useStyles = makeStyles((theme) => ({
  grid: {
    // backgroundColor: "grey",
    // height: "50vh",
    // textAlign: "center"
    position: "relative",
    width: "30%",
    margin: "0 auto",
    height: "auto",
    maxHeight: "70vh",
    marginTop: "calc(100vh - 55vh - 20px)",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "20px",
    border: "1px solid #999",
    overflow: "auto",
    borderRadius: "8px",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "325px",
    color: "#D0E1F4",
    padding: "5px",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    backgroundColor: "rgb(0 0 0 / 49%)",
    marginTop: "0",
    //   opacity:"0.6",

    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  otpText: {
    textAlign: "center",
    fontSize: "17px",
    fontWeight: "700",
    lineHeight: "14px",
  },
}));

export default function Otp(props) {
  const timelimit = 120;
  const classes = useStyles();
  const navigate = useNavigate();
  const [otp, setotp] = useState("");
  const [error, seterror] = useState("");
  const [open, setOpen] = useState(true);
  const [newotptimer, setNewOtpTimer] = useState(timelimit);
  const [breakStartedTime, setBreakStartedTime] = useState("");

  var notificationToken = localStorage.getItem(
    "NotificationToken",
    "currentToken"
  );
  console.log("noti", notificationToken);
  const mobile_number = props.mobile_number;
  const closeModal = () => {
    setOpen(false);
  };
  // Notification task
  // fcm token put as parameter in verify otp for login api
  const handleverify = async () => {
    console.log("mobile_number", mobile_number);
    console.log("otptext", otp);
    var query = {
      mobile_number: mobile_number,
      otp: otp,
      fcm_token: notificationToken,
    };
    const response = await postDataFromApi("verifyOtpForLogin", query);

    console.log("response verifyOtpForLogin", response, response.data.code);
    if (response && response.data.code == 1) {
      console.log("went inside homescreen");
      if (
        response.data.data.client_id == undefined ||
        response.data.data.client_id == ""
      ) {
        response.data.data["client_id"] = 0;
        // console.log("modified response===>",response.data.data)
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("accessToken", "123");
        localStorage.setItem("successfullyLoggedIn", "Success");
        localStorage.getItem("NotificationToken", "currentToken");
        navigate("/home");
      } else {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("accessToken", "123");
        localStorage.setItem("successfullyLoggedIn", "Success");
        localStorage.getItem("NotificationToken", "currentToken");
        navigate("/home");
      }
    } else if (response.data.code == 3) {
      console.log("response.data.code==3===>", response);
      console.log(
        "response.data.code==3===>client_id",
        response.data.data.client_id
      );
      if (
        response.data.data.client_id == undefined ||
        response.data.data.client_id == ""
      ) {
        response.data.data["client_id"] = 0;
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("accessToken", "123");
        localStorage.setItem("successfullyLoggedIn", "Success");
        localStorage.getItem("NotificationToken", "currentToken");
      } else {
        localStorage.setItem("userData", JSON.stringify(response.data.data));
        localStorage.setItem("accessToken", "123");
        localStorage.setItem("successfullyLoggedIn", "Success");
        localStorage.getItem("NotificationToken", "currentToken");
      }
      // setOpen(false);
      closeModal();
      // alert(response.message)
      // swal({
      // text: response.data.message,
      //   icon: "error",
      //   })
      navigate("/SubscriptionAfterLogin", {
        state: {
          otpPhoneResponse: response.data.data,
          client_id: response.data.data.client_id,
        },
      });
    } else {
      if (response.data.message) {
        seterror(response.data.message);
        alert(response.data.message);
        console.log("error message", response.data.message);
      }
      // setotp(response.data)
      // console.log('query',query)
      // console.log('otp',response.data);
    }
  };
  const otpchange = (e) => {
    setotp(e);
    console.log("otptextcange", otp);
  };

  const resendOtp = () => {
    seterror("");
    var query = { mobile_number: mobile_number };
    const res = postDataFromApi("sendOtpForLogin", query);
    setNewOtpTimer(timelimit);
    console.log("resend", res);
  };
  var interval_ID = "";
  const strttimer = () => {
    setInterval(() => {
      displaytimer();
    }, 1000);
  };
  const _interval = useRef(null);
  const intervalFun = () => _interval.current;
  const clearCurrentInterval = () => {
    clearInterval(intervalFun());
  };
  const displaytimer = () => {
    var timer = newotptimer - 1;
    setNewOtpTimer(timer);
  };
  const replaceCurrentInterval = (newInterval) => {
    clearCurrentInterval();
    _interval.current = newInterval;
  };
  const startTimer = () => {
    interval_ID = setInterval(() => {
      // var timercount=newotptimer-1
      var timercount = newotptimer - 1;
      setNewOtpTimer(timercount);
      if (newotptimer < 0) {
        timercount = timelimit;
      }

      // const startDate =moment(new Date(new Date('2022/06/26')))
      // const endDate = moment(new Date(new Date()))
      // var diffDurationSeconds = endDate.diff(startDate, 'seconds')
      // var diffDurationMinutes = new Date(diffDurationSeconds * 1000).toISOString().substr(11, 8)
      // //console.log('diffDurationMinutes', diffDurationMinutes)
      // var split_time = diffDurationMinutes.split(':')
      // const time = split_time[0] + ' hr ' + split_time[1] + ' min ' + split_time[2] + ' sec'
      // //console.log('time', time)
      timercount = secondsToTime(timercount);
      setBreakStartedTime(timercount);
    }, 1000);

    replaceCurrentInterval(interval_ID);
  };

  useEffect(() => {
    startTimer();
  });
  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours > 9 ? hours : "0" + hours,
      m: minutes > 9 ? minutes : "0" + minutes,
      s: seconds > 9 ? seconds : "0" + seconds,
    };
    return obj;
  };

  return (
    <Popup
      className=""
      modal
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <div className={classes.paper} id="otp_con">
        <Grid
          container
          style={{ backgroundColor: "white" }}
          className={classes.grid}
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <a
            href="javascript:"
            style={{ marginLeft: "322px" }}
            className="close"
            onClick={closeModal}
          >
            <Icon_closegrey />
          </a>
          <Grid item xs={12} textAlign="center" marginTop="8px">
            <Paper elevation={0}>
              <Typography
                className={classes.otpText}
                style={{ color: "#2760A3", fontFamily: "Mulish" }}
              >
                Enter OTP sent on your mobile phone
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item spacing={3} justify="center">
              <OtpInput
                onChange={(e) => otpchange(e)}
                separator={<span>{/* <strong>.</strong> */}</span>}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 1rem",
                  fontSize: "2rem",
                  // color:"#2760A3",
                  borderBlockWidth: "1px",
                  borderBottomColor: "blue",
                  borderLeftColor: "transparent",
                  borderRightColor: "transparent",
                  borderTopColor: "transparent",
                }}
                focusStyle={{
                  outline: "none",
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleverify()}
              >
                Verify
              </Button>
              {error ? <span className="Responseerr">{error}</span> : ""}
              {/* {error ? <span className='Responseerr'>we got the error</span> : ""} */}

              <Grid className="otptimer">
                <span>
                  {newotptimer > 0 && breakStartedTime
                    ? breakStartedTime.m + ":" + breakStartedTime.s
                    : ""}
                </span>
                <Button
                  type="submit"
                  fullWidth
                  variant="text"
                  color="primary"
                  disabled={newotptimer > 0}
                  onClick={() => resendOtp()}
                >
                  Resend OTP
                </Button>
                {/* <OtpTimer
              
              minutes={1}
              seconds={1}
              // text="Didn't receive an OTP? Resend OTP in:"
              ButtonText="Resend OTP"
              resend={resendOtp}
              fullWidth
                variant="text"
               color="primary"
            /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Popup>
  );
}
