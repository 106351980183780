import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabsContents from "./TabsContents";
import { ReactComponent as TabImg_1 } from "../../Assets/call-a-person-tab-1.svg";
import { ReactComponent as TabImg_2 } from "../../Assets/call-a-person-tab-2.svg";
import { ReactComponent as TabImg_3 } from "../../Assets/call-a-person-tab-3.svg";
import { ReactComponent as TabImg_4 } from "../../Assets/call-a-person-tab-4.svg";
import ProccessingCall from "./ProccessingCall";
import NotconnectedCall from "./NotconnectedCall";
import Flag_Ind from "../../Assets/call-a-person-ind-flag.svg";
import Submit_btn from "../../Assets/call-a-person-main-form-v-call.png";
import Select from "react-select";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";

const CallaPerson_page = () => {
  const [is_notconnected, setNotconnected] = useState(false);
  const [is_selected, setSelected] = useState(false);
  const [is_preccessed, setProccessed] = useState(false);
  const [isvideocallstarted, setisvideocallstarted] = useState(false);
  const [tabvalue, setTabvalue] = React.useState(0);
  const [languages, setlanguages] = useState([]);
  const [notInyourContact, setnotInyourContact] = useState({
    name: "",
    contact_number: "",
    language_id: "",
  });
  const tabchange = (newValue) => {
    setTabvalue(newValue);
  };

  const initialValues = { name: "", contact_number: "", language_id: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [issubmit, setIssubmit] = useState(false);
  const [makeCall, setMakeCall] = useState(false);
  const [somevar, setSomevar] = useState(false);
  const [seconds, setSeconds] = useState(1);
  const [ctime, setctime] = useState();
  const options = [];
  useEffect(() => {
    getlanguages();
  }, []);
  const getlanguages = async () => {
    var query = "model=LanguageMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setlanguages(response.data.data);
    }
  };
  languages.map(function (content) {
    return options.push({ value: content._id, label: content.language });
  });
  const [selectedmember, setselectedmember] = useState({
    member: [],
    contactdetails: [],
  });
  useEffect(() => {
    getlanguages();
  }, []);
  useEffect(() => {}, [is_notconnected]);
   
  const doselectedmember = (contact, index, page = "") => {
    console.log("contact", contact);

    let selected = selectedmember.member;
    let singlecontactdetails = selectedmember.contactdetails;
    if (selected.includes(contact._id)) {
      const i = selected.indexOf(contact._id);
      if (i > -1) {
        selected.splice(i, 1);
        singlecontactdetails.splice(i, 1);
      }
    } else {
      selected.push(contact._id);
      singlecontactdetails.push(contact);
    }
    console.log(selected);
    if (page == "ViewSelectedTabs") {
      if (selected.length == 0) {
        setSelected(false);
      }
    }
    setselectedmember((formData) => ({
      ...formData,
      member: selected,
      contactdetails: singlecontactdetails,
    }));
    setNotconnected(true);
    console.log("selectedmember", selectedmember);
  };

  const NotInContactList = (e, page = "") => {
    setFormErrors(validate(formValues));
    setIssubmit(true);
    // console.log("(formErrors).length function",Object.keys(formErrors).length)

    // let selected = selectedmember.member
    // let singlecontactdetails = selectedmember.contactdetails
    // let data = []
    // data['contact_name'] = notInyourContact.name
    // data['contact_number'] = notInyourContact.contact_number
    // // alert(notInyourContact.language_id)
    // data['language_id'] = notInyourContact.language_id
    // data['_id'] = 'custom-1'

    // if (selected.includes('custom-1')) {
    //  const i = selected.indexOf('custom-1');
    //  if (i > -1) {
    //      selected.splice(i, 1);
    //      singlecontactdetails.splice(i, 1)
    //  }
    // }
    // else {
    //  selected.push('custom-1')
    //  singlecontactdetails.push(data)
    // }
    // console.log(selected)
    // setselectedmember((formData) => ({
    //  ...formData,
    //  member: selected,
    //  contactdetails: singlecontactdetails
    // }));
    // setNotconnected(true)

    // console.log('selectedmember', selectedmember)
  };
  const onCustomContactChange = (e) => {
    const { name } = e.target;
    setFormValues({ ...formValues, [name]: e.target.value });
    setFormErrors(validate(formValues));
    // console.log("formValues",formValues);
    var value = e.target.value;
    // console.log('value', value)
    setnotInyourContact((formData) => ({
      ...formData,
      [e.target.name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIssubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required";
    }
    if (!values.contact_number) {
      errors.contact_number = "Contact number is required";
    }
    // if (!isNaN(+values.contact_number)) {
    //   errors.contact_number_num = "contact number is should be a numeric value";
    // }
    if (!values.language_id) {
      errors.language_id = "Language is required";
    }
    return errors;
  };

  useEffect(() => {
    // console.log("(formErrors).length", Object.keys(formErrors).length);
    // console.log("useEffect somevar outside", somevar);
    // console.log("issubmit", issubmit);
    if (Object.keys(formErrors).length == 0 && issubmit) {
      //   console.log("useEffect formValues", formValues);
      setSomevar(true);

      // console.log("inside condition");

      // console.log("useEffect somevar inside", somevar);

      let selected = selectedmember.member;
      let singlecontactdetails = selectedmember.contactdetails;
      let data = [];
      data["contact_name"] = notInyourContact.name;
      data["contact_number"] = notInyourContact.contact_number;
      // alert(notInyourContact.language_id)
      data["language_id"] = notInyourContact.language_id;
      data["_id"] = "custom-1";

      if (selected.includes("custom-1")) {
        const i = selected.indexOf("custom-1");
        if (i > -1) {
          selected.splice(i, 1);
          singlecontactdetails.splice(i, 1);
        }
        // setNotconnected(true);
      } else {
        selected.push("custom-1");
        singlecontactdetails.push(data);
      }
      console.log(selected);
      setselectedmember((formData) => ({
        ...formData,
        member: selected,
        contactdetails: singlecontactdetails,
      }));
      setNotconnected(true);

      console.log("selectedmember", selectedmember);
    }
  }, [formErrors]);

  function getSelectedItem(id, data = []) {
    console.log("data", options);
    console.log("data", id);
    const item = data.find((opt) => {
      if (opt.value == id) return opt;
    });
    console.log("item", item);
    return item || null;
  }
  const dropdownChange = (selectedOption, type = "") => {
    setFormErrors(validate(formValues));
		//   multiselectchange(selectedOption.value);
		console.log(`Option selected:`, selectedOption.value);
		if (type == 'language') {
			// setFormData(selectedOption)
			setFormValues((formValues) => ({
				...formValues,
				[type]: selectedOption.value,
			}));
      setnotInyourContact((formData) => ({
        ...formData,
        // [selectedOption.target.name]: selectedOption.value,
      }));
		}
		
	  };

  return (
    <>
      {!is_notconnected ? (
        <div className="page-main">
          <div className="page-main-inner">
            {/* <h1>Number of seconds is {seconds}</h1>  */}
            <Box>
              <Grid container spacing={3} className="main-grid">
                <Grid item md={6} className="call-person-profile">
                  <div className="box-shadow text-center radius-8px">
                    <div className="call-person-profile-inner">
                      <Tabs
                        value={tabvalue}
                        onChange={(event, newValue) => tabchange(newValue)}
                        className="call-person-tab-header custom_scrollbar hidetab"
                      >
                        <Tab label="Call" icon={<TabImg_1 />} />
                        {/*<Tab label="Recent Calls"
                                            icon={<TabImg_2/>}
                                            />
                                            <Tab label="Contacts"
                                            icon={<TabImg_3/>}
                                            />
                                            <Tab label="My Groups"
                                            icon={<TabImg_4/>}
                                            />*/}
                      </Tabs>
                    </div>
                    <TabsContents
                      tabvalue={tabvalue}
                      doselectedmember={doselectedmember}
                    />
                  </div>
                </Grid>
                {/* </div>
                                {is_selected ?
                                                <ViewSelectedTabs tabvalue={tabvalue} setProccessed={setProccessed} selectedmember={selectedmember} doselectedmember={doselectedmember} />
                                                :
                                                <TabsContents tabvalue={tabvalue} selectedmember={selectedmember} doselectedmember={doselectedmember} />
                                            }
                                        </div>
                                    </Grid> */}

                <Grid item md={6} className="call-person-form">
                  <div className="box-shadow radius-8px">
                    <div className="call-person-form-inner">
                      <div className="call-person-form-title">
                        <h3>Not in your contact list?</h3>
                      </div>
                      <div className="call-person-form-main">
                        <div className="call-person-form-main-inner">
                          <form onSubmit={handleSubmit}>
                            <div className="form-div">
                              <label>Name of Person</label>
                              <input
                                type="text"
                                name="name"
                                value={formValues.name}
                                className="name-of-person"
                                id="name"
                                placeholder="Enter name"
                                required
                                onChange={(e) => onCustomContactChange(e)}
                              />
                            </div>
                            <p
                              style={{ color: "red" }}
                              className={formErrors.name ? "pform1" : "pform2"}
                            >
                              {formErrors.name}
                            </p>
                            <div className="form-div">
                              <label className="img-label">
                                <img src={Flag_Ind} />
                                <input
                                  type="number"
                                  name="contact_number"
                                  value={formValues.contact_number}
                                  className="phone-number"
                                  id="contact_number"
                                  placeholder="+91  Enter phone number"
                                  onChange={(e) => onCustomContactChange(e)}
                                />
                              </label>
                            </div>
                            <p
                              style={{ color: "red" }}
                              className={
                                formErrors.contact_number ? "pform1" : "pform2"
                              }
                            >
                              {formErrors.contact_number}
                            </p>
                            <div className="form-div">
                              <label>Language</label>
                              <select
                                name="language_id"
                                style={{color:"black"}}
                                value={formValues.language_id}
                                className="language"
                                id="language_id"
                                onChange={(e) => onCustomContactChange(e)}
                              >
                                <option style={{color:"black" }} value="default" selected>
                                  Select language of the person
                                </option>
                                {languages.map(function (language, i) {
                                  return (
                                    <option style={{color:"black"}} value={language._id}>
                                      {language.language}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* <Select
                                options={options}
                                // styles={style}
                                id="language_id"
                                isSearchable={true}
                                placeholder="select language"
                                //  onChange={(selectedOption) => handleChange(selectedOption)}
                                onChange={(selectedOption) =>
                                  dropdownChange(selectedOption, "language")
                                }
                                // value={formdata.language}
                                value={getSelectedItem(
                                  formValues.language,
                                  options
                                )}
                                //valueKey={_id}
                              /> */}
                               
                            </div>
                            <p
                              style={{ color: "red" }}
                              className={
                                formErrors.language_id ? "pform1" : "pform2"
                              }
                            >
                              {formErrors.language_id}
                            </p>
                            <div
                              className="form-div submit-call"
                              style={{ height: "60px" }}
                            >
                              <a
                                href="javascript:"
                                onClick={(e) =>
                                  NotInContactList(
                                    selectedmember.member.includes("custom-1")
                                  )
                                }
                              >
                                <img src={Submit_btn} />
                              </a>
                              {/* <input
                                                        type="button"
                                                        name="video-call"
                                                        className="video-call"
                                                        id="video-call"
                                                        /> */}
                            </div>
                            <div
                              className="form-div cb-div"
                              style={{ marginTop: "20px" }}
                            >
                              <input
                                type="checkbox"
                                id="need-inter"
                                name="need-inter"
                                className="need-inter"
                                value="need-inter"
                              />
                              <span className="checkmark"></span>Need
                              interpreter of same gender
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      ) : (
        <ProccessingCall
          setNotconnected={setNotconnected}
          selectedmember={selectedmember}
          doselectedmember={doselectedmember}
          setProccessed={setProccessed}
          setSelected={setSelected}
          setisvideocallstarted={setisvideocallstarted}
          language_id={notInyourContact.language_id}
        />
      )}
    </>
  );
};

export default CallaPerson_page;
