import React,{useEffect,useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import ListItemButton from '@mui/material/ListItemButton'
import SearchIcon from "../Assets/call-a-person-search-img.svg";
import Contact_img from "../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../Assets/call-a-person-contact-img3.svg";
import {ReactComponent as Clock} from "../Assets/clock 1.svg";
import Spinner_150 from "../Assets/Spinner-1s-150px.svg";
import { ReactComponent as Icon_closegrey } from "../Assets/close-grey.svg";
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../Service/CommonService';
import {getprofile,getAuth} from '../Service/Helper';
import moment from 'moment';
import {TextField,Box } from "@material-ui/core";

import Popup from 'reactjs-popup';
const InterpreterBreakPage = (props) => {
	const [is_Callloaded,setCallLoaded]=useState(false)
	const [onBreak ,setonBreak]=useState(false)
	const [open, setOpen] = useState(false);
	const [mycontacts,setmycontacts]=useState([]);
	const [mybreak,setmybreak]=useState([])
	const [formdata, setfromdata] = useState({ interpreter_id: 77, reason: "" })
	const authdata=getAuth()
	const userid=authdata['_id'];
	const contactDetails=[];
	const interpreterid=77
	const breakReasons = ['Lunch', 'Restroom', 'Coffee', 'Meeting', 'Feeling unwell', 'Technical issue', 'Other']
	const time=''
	useEffect(() => {
	    getMyContact();
		// getAvailableInterpreter();
	}, []);

	const closeModal = () => {
		setOpen(false);
	}

	const openModal = () => {
		setOpen(true);
	}
	const getMyContact = async () => {
		setCallLoaded(true);
	    var query = "model=interpretorAvailibilityLogMaster&interpeter_id="+interpreterid
		
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setmycontacts(response.data.data);
	    }
        setCallLoaded(false);
	} 
    
	var data = {
		"interpeter_id": 77,
		"reason": "reason"
	}
	const getAvailableInterpreter = async (newreason) => {
		console.log('newreason',newreason)
	    var query ="reason="+newreason+"&interpreter_id="+formdata.interpreter_id
		console.log('query',query)
		const response = await postDataFromApi('add/interpretorAvailibilityLog', query);
	    if(response.data.code && response.data.data!=null){
	    	setmybreak(response.data.data);
	    }
       
	} 

	const ongrpchange = (item) => {
		const newreason=item
		console.log('newreason',item)
		setTimeout(function(){
			setfromdata((formData) => ({
				...formData,
				'reason': newreason,
			
			}));
			console.log('formdata',formdata)
			getAvailableInterpreter(newreason)
			getMyContact()
		},1000)
		
	}


     const startTime= moment(mycontacts.created_at)
	 const EndTime = moment(mycontacts.updated_at)
    // const start = mycontacts.created_at
	// const End =mycontacts.updated_at 
	// const diff= (End-start)/(1000*60)

	// console.log('diff',startTime)
	// console.log('end',EndTime)

	// var diffDurationSeconds =startTime.diff(EndTime, 'seconds')
	// var diffDurationMinutes = new Date(diffDurationSeconds * 1000).toISOString().substr(11, 8)
	// var split_time = diffDurationMinutes.split(':')
 	// time = split_time[0] + ' hr ' + split_time[1] + ' min ' + split_time[2] + ' sec '

function getDifferenceInDays(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}

function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60);
}

function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}
function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hr, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " min, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " sec") : "";
    return hDisplay + mDisplay + sDisplay; 
}

	const [show, setShowprofile] = React.useState();
	return (
        <Grid item md={5} className="my-contacts" style={{marginTop:"3%",marginLeft:"2%"}}>
        <div className="box-shadow radius-8px vertical_scrollbar">
          <div className="my-contacts-inner">
             
                
              {/* { is_grouploaded ? <img className="loading_spinner" src={Spinner_150} />   : ''} */}
              {/* <div className="mycontacts-detail vertical_scrollbar"> */}
			  {
				(mycontacts.length>0) ?
				mycontacts.map(function(contact, i){
					const date1 =moment(new Date(contact.created_at));
					if(contact.type==2){
						const date2 = moment(new Date(contact.updated_at));
                        
						
						var diffDurationSeconds =date1.diff(date2, 'seconds')
						// const diffDurationMinutes = new Date(diffDurationSeconds * 1000)
						// const split_time = diffDurationMinutes.split(':')
						// const time = split_time[0] + ' hr ' + split_time[1] + ' min ' + split_time[2] + ' sec '
						console.log('date1',date1)
						console.log('date2',date2)
						console.log('duration',diffDurationSeconds )
						
				
					}
                  
                   
				
	 
				//    console.log(getDifferenceInDays(date1, date2));
				//    console.log(getDifferenceInHours(date1, date2));
				// 	 console.log(getDifferenceInMinutes(date1, date2));
				// 	console.log(getDifferenceInSeconds(date1, date2));
				return (
                
                    <Paper elevation={2} className="call-person-details " style={{margin:"2%"}}>
							<Grid className="call-person-img">
								<p className="single_profinitial-interpreter">AP</p>
							</Grid>
						
                            <Grid  className="call-person-inner-details">
								<span className="missedcall-names">{contact.reason}</span>
                               <div className="interpreter-time"> {moment(contact.created_at).format('DD MMMM YYYY')}</div>
                            </Grid>
							<Grid md={4} className="call-person-inner-details-new">
								<span className="missedcall-names-1"><Clock/>{moment(contact.created_at).format('HH:mm')}-{moment(contact.updated_at).format('HH:mm')}</span>
                               <div className="interpreter-time-1">{secondsToHms(500)} </div>
                            </Grid>
                 </Paper>
				
			 );
			}) :	(<div className="single_contact">
			     <p className="nodata_found">No Data Found</p>
		   
		      </div>)
		}
       </div>
        </div>
		<Grid md={12} >
				<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={() => openModal()}
				>Start Break </Button>
				 </Grid>
                   
                 <Grid md={12} >
				 <h3 className='text'>00 hr 00 min 00 sec</h3>
			
				 </Grid>

    <Popup className="processingcall-modal language"
     modal
     open={open}
     closeOnDocumentClick
     onClose={closeModal}
    >

<div className="modal interpreter">
	<button className="close" onClick={closeModal}>
		<Icon_closegrey />
	</button>
	
	<div className="content special-svg">
		{' '}
		{/* <div className="form-div"> */}
		
			{/* <TextField
				style={{ width: "80%" }}
				disableUnderline
				placeholder="Enter a Group Name"
				
				name="circle_title"
				// onChange={(e) => ongrpchange(e)}
			/> */}
			<Box  justifyContent="space-between">
				
			{breakReasons.map((item, key) => (
			<Button variant='outlined' className="break-reason"
			 onClick={(e)=> ongrpchange(item)} >
			  {item}
			</Button>
			 ))}
			</Box>
            

		{/* </div> */}
	</div>
	
</div>

</Popup>
   </Grid>
   
	)
}


export default InterpreterBreakPage;