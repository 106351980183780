import React,{useEffect,useState} from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {getToken,createMeeting,sdkAPIKey,callSetup,startTimerToGetCallDetails} from '../../Service/videoskp';

const VideoCall_page = () => {

   const[token,settoken]=useState([])
   const[MeetingData,setMeetingData]=useState([])

 // const token = await getToken();
  const gettoken = async () => {
        var query = ""
        const token = await getToken();
        console.log('get token',token)
        if(token && token!=null){
          settoken(token);
          getMeetingData(token);

        }
  }
  const getMeetingData = async (token) => {
        var query = ""
        const response = await createMeeting(token);
        console.log('response',response)
        if(response &&response!=null){
          setMeetingData(response)
          console.log(response.meetingId)
          startmeeting(response.meetingId)
        }
        
        
  }
  
  const startmeeting=(meetingId)=>{
    //here call start meeting api before initiate meeting our own api will be call
    const config = {
      name: "Sufiyan",
      meetingId: meetingId,
      apiKey:sdkAPIKey,

      containerId: null,

      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,

      chatEnabled: true,
      screenShareEnabled: true,
      canChangeLayout: true,
      joinScreen: {
        visible: true,
      },
      /*

     Other Feature Properties
      
      */
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);

  }
  useEffect(() => {
    gettoken();
    document.body.className = 'videocall';
    return () => { document.body.className = ''; }
    
  }, []);

  return <div className="videoall"></div>;
}

export default VideoCall_page;