import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, Select, Radio, RadioGroup, FormControlLabel, Button, Modal, } from "@material-ui/core";
import Icon_rupeesimage from "../../Assets/rupeesimg.svg";
import { postDataFromApi, getDataFromApi, putDataFromApi } from '../../Service/CommonService';
import { getprofile, getAuth } from '../../Service/Helper';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import swal from "sweetalert"
import MainLogo from "../../Assets/header-main.svg";

const ViewBalance_page = () => {
	//https://signabledevapi.disctesting.in/api/all/ActivesubscriptionPlansList
	const navigate = useNavigate()
	const authdata = getAuth()
	const userid = authdata['_id'];
	//const profile=getprofile()
	console.log('profile', authdata)
	// const user_balance_call_minutes = authdata['user_balance_call_minutes']
	// console.log('user_balance_call_minutes', user_balance_call_minutes)
	// const user_balance_call_minutes=localStorage.setItem('user_balance_call_minutes', user_balance_call_minutes)

	const [subscriptionlist, setsubscriptionlist] = useState([])
	const [user_balance_call_minutes, setuser_balance_call_minutes] = useState('')
	// const getsubscriptionlist = async () => {
	// 	var query = "user_id=" + userid + "&type=0"
	// 	// const response = await postDataFromApi('getSubscriptionData', query);
	// 	const response = await getDataFromApi('all/ActivesubscriptionPlansList', query);
	// 	// const response = await postDataFromApi('getSubscriptionData', query);
	// 	console.log('subscriptionlist',)
	// 	if (response.data.code && response.data.data != null) {
	// 		setsubscriptionlist(response.data.data);
	// 	}
	// }
	const getsubscriptionlist = async () => {
		// var query = ""
		// const response = await getDataFromApi('all/ActivesubscriptionPlansList', query);
		var query = "user_id="+userid+"&type=0"
		const response = await postDataFromApi('getSubscriptionData', query);
		// const response = await getDataFromApi('all/ActivesubscriptionPlansList', query);
		console.log('subscriptionlist',response)
		if(response.data.code && response.data.data!=null){
			setsubscriptionlist(response.data.data);
		}
}

	const getProfileDetails = async () => {
		const response = await getDataFromApi('find/userProfile/'+userid);
		console.log('subscriptionlist',)
		if (response.data.code && response.data.data != null) {
			setuser_balance_call_minutes(response.data.data[0].user_balance_call_minutes)
		}
	}
	useEffect(() => {
		getsubscriptionlist();
		getProfileDetails();

	}, []);

	const openPayModal = async (subscriptionlist,index) => {
    	console.log('subscriptionlist',subscriptionlist)
        
    	console.log(authdata)
        var query = "user_id="+userid+"&amount="+subscriptionlist.amount*100
    	const response = await postDataFromApi('subscriptionorder', query);
        console.log('subscriptionorder',response)
        
        if(response.data.code && response.data.data!=null){
        	
        	const options = {
		        key: 'rzp_test_LomgTJap9dTFn3',
		        amount: subscriptionlist.amount*100, //  = INR 1
		        name: 'SignAble',
		        description: subscriptionlist.plan_title,
		        image: MainLogo,
		        order_id: response.data.data.id,
		        handler: async  function(response) {


                    console.log('response',response)
		        	console.log('razorpay_payment_id',response.razorpay_payment_id);
			        console.log('razorpay_order_id',response.razorpay_order_id);
			        console.log('razorpay_signature',response.razorpay_signature);

		            

                   if(response && response.razorpay_payment_id && response.razorpay_order_id && response.razorpay_signature){
                   	    var values ={
			                "user_id": userid,
				            "amount": subscriptionlist.amount,
				            "razorpay_order_id": response.razorpay_order_id,
				            "razorpay_payment_id": response.razorpay_payment_id,
				            "razorpay_signature": response.razorpay_signature,
				            "status": 'Success',
				            "subscription_id": subscriptionlist._id,
				            "transaction_for": "1",
				            "transaction_type": "1",
			            }
                   	   const result = await postDataFromApi('paymentresponce', values);
                   	   console.log('paymentresponce',result)
                       if(result.data.code && result.data.data!=null){
                       	 if(result.data.code===1)
			               {
				                swal({
				                    // title: "client added!",
				                  text: result.data.message,
				                    icon: "success",
				                    
				                })
				            }
				            else{
				                swal({
				                    // title: "client added!",
				                  text: result.data.message,
				                    icon: "error",
				                    })
				            }
                       	  const userresponse = await getDataFromApi('find/userProfile/'+userid, query);
                       	  console.log('userresponse',userresponse);
                       	  if(userresponse.data.code && userresponse.data.data!=null){
                       	 	localStorage.setItem('userData', JSON.stringify(userresponse.data.data[0]));
                       	 }

                       }

                   	   
                   }
		        	
		        },
		        prefill: {
		            name: authdata.first_name,
		            contact: authdata.mobile_number,
		            email: authdata.email
		        },
		        notes: {
		            address: ''
		        },
		        theme: {
		            // color: 'blue',
		            color: '#2760A3',
		            hide_topbar: false,
		        }
		    };
           var rzp1 = new window.Razorpay(options);
           rzp1.open();
        }
	}


	return (
		<div className="page-main">
			<div className="page-main-inner viewaccount_balance">
				<Box>
					<Grid container spacing={3} className="main-grid">
						<Grid item md={8} className="view-balance">
							<div className="box-shadow text-center radius-8px" style={{ height: "100%" }}>
								<div className="walletbalance">
									<Grid container spacing={3}>
										<Grid item md={4} className="view-balance">
											<div>Wallet Balance</div>
										</Grid>
										<Grid item md={4} className="view-balance">
											<img src={Icon_rupeesimage} />
										</Grid>
										<Grid item md={4} className="view-balance">
											<div className="amount">{user_balance_call_minutes}</div>
										</Grid>
									</Grid>


								</div>
								<div className="call-person-tab-header">
									<h3>My Plan</h3>
								</div>
								<div className="plans_inner vertical_scrollbar">
									{/* {subscriptionlist.map((subscriptionlist, index) => (
										<div className="plans renew">
											<div className="plans-amt">{subscriptionlist.plan_title} {subscriptionlist.amount}/-</div>
											<div className="plansdetail">
												<div className="plansdetail_inner">
													<ul>
														<li>Minutes:  {subscriptionlist.subscription_duration} </li>

														// <li>{moment(subscriptionlist.end_date).diff(moment(subscriptionlist.start_date), 'days')} Days</li>
														<li>Start date: {subscriptionlist.valid_from}</li>
														<li>End date:   {subscriptionlist.valid_till}</li>
													</ul>
												</div>
												// <Button className="primary" variant="text"  >Buy Now</Button>
											</div>
										</div>
									))} */}
									{subscriptionlist.map((subscriptionlist, index) => (
									<div className="plans">
										<div className="plans-amt">{subscriptionlist.plan_title} {subscriptionlist.amount}/-</div>
										<div className="plansdetail">
										   <div className="plansdetail_inner">
										      <ul>
										        <li>{subscriptionlist.call_minutes_allocated} Mins</li>
										        <li> {moment(subscriptionlist.end_date).diff(moment(subscriptionlist.start_date), 'days')}  Days</li>
										      </ul>
										   </div>
										   {/* <Button className="primary" variant="text" onClick={(e)=>openPayModal(subscriptionlist,index)}>Buy Now</Button> */}
										</div>
									</div>
								))}
								</div>
								{/* <div className="tryfreetrial">Try out the free trial</div> */}
								<div className="allchange_plan">
									<Button className="primary" variant="text" onClick={() => navigate('/ViewAllplans')}>All Plans</Button>
									<Button className="primary" variant="text" onClick={() => navigate('/ViewAllplans')} >Change Plan</Button>
								</div>
							</div>
						</Grid>
					</Grid>
				</Box>

			</div>
		</div>
	)
}

export default ViewBalance_page;