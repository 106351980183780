import React, { useEffect, useState,useRef } from "react";
import Home from "./../HomeComponent/Home";
import Select from "react-select";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import {
  Routes,
  Route,
  NavLink,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import Popup from "reactjs-popup";
import Side_2 from "../../Assets/sidebar-2.svg";
import { ReactComponent as Icon_closegrey } from "../../Assets/close-grey.svg";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";
import NotconnectedCall from "./../ConferenceComponent/NotconnectedCall";
import ProccessingCall from "./../ConferenceComponent/ProccessingCall";
import Interpreter_profile from "../../Assets/interpreter-profile.svg";
import Multiple_userwhite from "../../Assets/multiple-user-white.svg";
import Two_user from "../../Assets/two_user.svg";
import Two_userwhite from "../../Assets/two-user-white.svg";
import Connecting_interpreter_1 from "../../Assets/connecting-interpreter-1.svg";
import Connecting_interpreter_2 from "../../Assets/connecting-interpreter-2.svg";
import { ReactComponent as Iocn_Callback } from "../../Assets/callback.svg";
import { ReactComponent as Iocn_Cancel } from "../../Assets/cancel.svg";
import { ReactComponent as Iocn_Notconnect_interpreter } from "../../Assets/notconnect-interpreter-girl.svg";
import { ReactComponent as Icon_crossbordercancel } from "../../Assets/cross-border-cancel.svg";
import { ReactComponent as Iocn_Cancel_call } from "../../Assets/cancel-call.svg";
import Connecting from "../../Assets/connecting.svg";
import { ReactComponent as Icon_time } from "../../Assets/time.svg";
import { getprofile, getAuth } from "../../Service/Helper";
import {
  getToken,
  createMeeting,
  sdkAPIKey,
  callSetup,
  startTimerToGetCallDetails,
  custoclearinterval,
  BaseUrl,
  CallStatusUpdateData,
} from "../../Service/videoskp";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import $ from "jquery";
import swal from "sweetalert";

const GetInterpreter_page = () => {
  const [languages, setlanguages] = useState([]);
  const [is_preccessed, setProccessed] = useState(false);
  const [is_notconnected, setNotconnected] = useState(false);
  const [languageId, setlanguageId] = useState("");
  const [formdata, setFormData] = useState({ language: "" });
  const authdata = getAuth();
  const userid = authdata["_id"];
  const [token, settoken] = useState([]);
  const [MeetingData, setMeetingData] = useState([]);
  const [MeetingId, setMeetingId] = useState("");
  const [CallResponseData, setCallResponseData] = useState({});
  const [intervalId, setIntervalId] = useState(false);
  const [isCallReceived, setisCallReceived] = useState(false);
  const [newIntervalId, setnewIntervalId] = useState("");
  const [isvideocallstarted, setisvideocallstarted] = useState(false);
  const [is_selected, setSelected] = useState(false);
  const [languageShow, setlanguageShow] = useState(false);
  const [isCallbackRequest, setisCallbackRequest] = useState("");
  const [Error, setError] = useState('')
  const options = [];
  let iscallback = false;

  var processed = false;
  var singlenewIntervalId = "";

  console.log(authdata);
  useEffect(() => {
    getlanguages();
  }, []);
  const getlanguages = async () => {
    var query = "model=LanguageMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setlanguages(response.data.data);
    }
  };

  const chosedlanguage = async (e) => {
    // setProccessed(true)
    console.log("formdata.language",formdata.language);
    if (formdata.language) {
      gettoken();
    }else{
      setError("Please Select Language")
    }
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const closeModal = () => {
    navigate("/Home");
    setOpen(false);
  };
  function changedropdownvalue(e, type) {
    console.log(type);
    console.log(e.target.value);
    if (e) {
      var value = e.target.value;
    } else {
      var value = "";
    }
    setFormData((formData) => ({
      ...formData,
      [type]: value,
    }));
  }

  const gettoken = async () => {
    var query = "";
    const token = await getToken();
    console.log("get token", token);
    if (token && token != null) {
      settoken(token);
      getMeetingData(token);
    }
  };
  //show on  error on thisapi
  //https://signableapi.disctesting.in/api/add/CallSetup
  // code: 0, message: "You don't have an active subscription."
  const getMeetingData = async (token) => {
    var query = "";
    const response = await createMeeting(token);
    console.log("response", response);
    if (response && response != null) {
      setMeetingData(response);
      setMeetingId(response.meetingId);
      console.log(response.meetingId);

      //startmeeting(response.meetingId)
      settingCall(response);
      setProccessed(true);
    }
  };

  const settingCall = async (data) => {
    console.log("data", data);
    var contactDetailsArray = [];

    contactDetailsArray = [
      {
        contact_number: authdata["mobile_number"],
        name: authdata["first_name"],
      },
    ];

    var inputData = JSON.stringify({
      user_id: userid,
      call_type: "1",
      language_id: formdata.language,
      purpose_id: "1",
      meetingDetails: data,
      contactDetails: contactDetailsArray,
      user_type: authdata["user_type"],
      individual_user_type: authdata["user_category"],
      is_same_gender: "0",
      initiator_gender: authdata["gender"],
      initiatorFullName: authdata["first_name"],
      appVersion: 1,
      source: "web",
    });

    console.log("inputData", inputData);

    /* call setup API */

    const callResponse = await callSetup(inputData);
    console.log("callresponse", callResponse);
    setCallResponseData(callResponse);
    console.log("callResponse callsetup", callResponse);
    //console.log('callResponse',callResponse.data.meetingId)

    if (callResponse && callResponse.code) {
      if (!intervalId) {
        var calltimeout = setTimeout(function () {
          console.log("iscallback", iscallback);
          if (!isCallReceived && !processed) {
            CallStatusUpdateData(callResponse.data._id, "7", "6");
            clearInterval(singlenewIntervalId);
            setIntervalId(true);
            setNotconnected(true);
          }
        }, 30000);
        setisCallbackRequest(calltimeout);
        singlenewIntervalId = setInterval(function () {
          startTimer(callResponse);
        }, 3000);
        setnewIntervalId(singlenewIntervalId);
      } else {
        swal({
          //text: callResponse.message,
          text: "You are blocked,please contact admin",
        }).then(function () {
          navigate("/home");
        });
        //clearInterval(singlenewIntervalId);
      }
      //clearInterval(singlenewIntervalId);
      /*if (intervalId) {
    		  clearInterval(singlenewIntervalId);

    		  console.log("  cc clear")

    	  }*/
    }

    //////////////////////////////////////////////////////////////////09012023

    if (callResponse && callResponse.code == 1) {
      if (!intervalId) {
        var calltimeout = setTimeout(function () {
          if (!isCallReceived && !processed) {
            CallStatusUpdateData(callResponse.data._id, "7", "6");
            clearInterval(singlenewIntervalId);
            setIntervalId(true);
            setNotconnected(true);
          }
        }, 45000);
        setisCallbackRequest(calltimeout);
        startTimer(callResponse);
      }
    } else if (callResponse.code == 3) {
      clearInterval(singlenewIntervalId);
      swal({
        text: "No Interpreter available",
        icon: "error",
      }).then(function () {
        navigate("/home");
      });
    } else {
      //alert(callResponse.message)
      if (callResponse.message === "User Not found") {
        clearInterval(singlenewIntervalId);
        swal({
          text: "Your are blocked. Please contact admin team.",
          icon: "error",
        }).then(function () {
          navigate("/home");
        });
      } else {
        clearInterval(singlenewIntervalId);
        swal({
          text: callResponse.message,
          icon: "error",
        }).then(function () {
          navigate("/home");
        });
      }

      //clearInterval(singlenewIntervalId);
      //clearCurrentInterval()
      //clearInterval(interval);
      //   clearCurrentInterval();
    }

    //////////////////////////////////////////////////////////////////09012023
  };


  // const startTimer = async (data) => {
  // 	/*const response1 = await getDataFromApi('getcallDetails/'+MeetingId);
  // 	console.log('console.log(response)',response1)*/
  // 	const response = await startTimerToGetCallDetails(data.data._id, authdata['first_name'], authdata['user_type'], token);
  // 	console.log('startTimer', response)

  // 	if (response && response.data !== "data not found") {
  // 		console.log(newIntervalId);
  // 		// response.data.status = 2
  // 		if (response.data.status == 2) {
  // 			if (!processed) {
  // 				processed = true;
  // 				clearInterval(newIntervalId);
  // 				setIntervalId(true);
  // 				CallStatusUpdateData(data.data._id, '3', '3')
  // 				startmeeting(data.data.meetingId, data.data._id)
  // 				setisCallReceived(true)
  // 			}
  // 		}
  // 	}

  // }
  // code for round robin method
  ////////////////////////////////////////////////////////////////////////////////////////old code
  const startTimer = async (data) => {
    console.log("Id", data._id);
    var count = 0;
    singlenewIntervalId = setInterval(async function () {
      const response = await startTimerToGetCallDetails(
        data.data._id,
        authdata["first_name"],
        authdata["user_type"],
        token
      );
      console.log("startTimer", response);
      count += 2;
      if (response && response.data !== "data not found") {
        console.log(newIntervalId);
        // response.data.status = 2
        if (response.data.status == 2) {
          if (!processed) {
            processed = true;
            setisCallReceived(true);
            clearInterval(newIntervalId);
            setIntervalId(true);

            //when call start
            CallStatusUpdateData(data.data._id, "3", "3");

            startmeeting(data.data.meetingId, data.data._id);
          }
        } else if (response.data.status === 1) {
          if (count === 16 || count === 26) {

            checkIsCallConnected(data.data._id);
          }
        }
      }
    }, 2000);
    setnewIntervalId(singlenewIntervalId);
    // replaceCurrentInterval(singlenewIntervalId);
    /*const response1 = await getDataFromApi('getcallDetails/'+MeetingId);
		console.log('console.log(response)',response1)*/
  };
  // interpreter join code api
  const checkIsCallConnected = async (callSetupid) => {
    console.log("checkIsCallConnected", callSetupid);
    const response = await getDataFromApi(
      "sendNotificationRequestForInterpreterJoin/" + callSetupid
    );
    if (response && response.data.code === 0) {
      swal({
        // title: "client added!",
        text: "Sorry, There is no interpreter available.",
        icon: "error",
      }).then(function () {
        // clearInterval(singlenewIntervalId);
        // clearInterval(newIntervalId);
        navigate("/home");
      });

      clearInterval(singlenewIntervalId);
      clearInterval(newIntervalId);
    }
    // })
  };

///////////////////////////////////////////////////////////////////////////////////////////
  const startmeeting = async (meetingId, callSetupid) => {
    localStorage.setItem("callSetupid", callSetupid);
    localStorage.setItem("startedTime", new Date());
    //here call start meeting api before initiate meeting our own api will be call
    const config = {
      name: authdata["first_name"],
      meetingId: meetingId,
      apiKey: sdkAPIKey,
      // joinScreen: {
      // 	visible: false,

      // },
      containerId: "meeting-container-id",
      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,

      chatEnabled: true,
      screenShareEnabled: true,
      canChangeLayout: true,
      redirectOnLeave: window.location.origin,
      // redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
      joinScreen: {
        visible: false,
      },
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
    $(".full-screen").insertAfter(".page-main");

    setisvideocallstarted(true);
    setNotconnected(true);
  };

  const CallStatusUpdate = async (buttonType) => {
    clearInterval(newIntervalId);
    setIntervalId(true);

    if (buttonType == "cancel") {
      console.log("CallResponseData as", CallResponseData);
      const response = await CallStatusUpdateData(
        CallResponseData.data._id,
        "6",
        "6"
      );
      console.log("cancel", response);
      if (response && response.code) {
        setNotconnected(false);
        setNotconnected(false);
        setProccessed(false);
        setSelected(false);
        clearInterval(singlenewIntervalId);
        navigate("/Home");
      }
    } else if (buttonType == "callback") {
      const response = await CallStatusUpdateData(
        CallResponseData.data._id,
        "5",
        "6"
      );
      console.log(response);
    }
  };

  const callbackrequest = async () => {
    global.clearTimeout(isCallbackRequest);
    const response = await CallStatusUpdateData(
      CallResponseData.data._id,
      "5",
      "6"
    );

    navigate("/Home");
  };
  // 7 matlab interpreter ne call nhi uthya 6 matlb user ne cancel calling
  languages.map(function (content) {
    return options.push({ value: content._id, label: content.language });
  });
  //   console.log("languages", languages)

  const handleChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setlanguageShow(selectedOption);

    console.log(`Option selected:`, selectedOption.value);
    changedropdownvalue(selectedOption.value);
  };
  const dropdownChange = (selectedOption, type = "") => {
    //   multiselectchange(selectedOption.value);
    console.log(`Option selected:`, selectedOption.value);
    if (type == "language") {
      // setFormData(selectedOption)
      setFormData((formData) => ({
        ...formData,
        [type]: selectedOption.value,
      }));
    }
  };
  function getSelectedItem(id, data = []) {
    console.log("data", data);
    console.log("data", id);
    const item = data.find((opt) => {
      if (opt.value == id) return opt;
    });
    console.log("item", item);
    return item || null;
  }
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),

    control: (_, { selectProps: { width } }) => ({
      width: 200,
    }),
  };
  return (
    <>
      {!is_notconnected ? (
        <>
          {!is_preccessed ? (
            <>
              <Home />
              <Popup
                className="processingcall-modal language"
                modal
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
              >
                <div className="modal interpreter">
                  <button className="close" onClick={closeModal}>
                    <Icon_closegrey />
                  </button>
                  <div className="header"> Select language </div>
                  <div className="content special-svg">
                    {" "}
                    <div className="form-div" style={{height:60}}>
                      <label>Language</label>
                      {/* <select
												onChange={(e) => changedropdownvalue(e, 'language')}

												name="language"
												className="language"
												id="language"
												value={formdata.language}
											    >
												<option value="default" selected disabled>Select language of the person</option>
												{
													languages.map(function (language, i) {
														return (
															<option color="red" value={language._id}>{language.language}</option>
														)
													})
												}
										</select> */}
                    <div style={{marginTop:5}}>
                      <Select
                        options={options}
                        // styles={style}
                        styles={[customStyles]}
                        isSearchable={true}
                        placeholder="select language"
                        //  onChange={(selectedOption) => handleChange(selectedOption)}
                        onChange={(selectedOption) => {
                          dropdownChange(selectedOption, "language")
                          setError("")
                        }}
                        value={getSelectedItem(formdata.language, options)}
                        //valueKey={_id}
                        className="new-dropdown "
                      >
                        <p>hii</p>
                      </Select>
                      </div>
                    </div>
                  </div>
                  {Error && <span style={{fontSize:12, color:"red"}}>{Error}</span> }
                  
                  <div className="actions">
                    <Button
                      className="primary"
                      onClick={() => chosedlanguage()}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </Popup>
            </>
          ) : (
            <div className="page-main">
              <div className="page-main-inner conference">
                <div className="processing-call-tab-content">
                  <div className="processing-call-tab-inner">
                    <div className="calling-names-dash">
                      <div className="inte-profile">
                        <img src={Interpreter_profile} />
                      </div>
                      <div className="inte-profile-details">
                        <span>
                          {authdata.first_name + " " + authdata.last_name}
                        </span>
                        {/* <div>
			<span className="connecting_person">{authdata.length} <img src={Multiple_userwhite} /></span>
			<span className="connecting_person_names"><img src={Two_userwhite} /> {authdata.first_name}{authdata.length > 1 ? '+' : ''}{authdata.length > 1 ?  authdata.length - 1 : ''}</span>
			</div> */}
                      </div>
                    </div>
                    <div className="connect-interpreter">
                      <img src={Connecting} />
                      {/* {authdata.first_name ? authdata.first_name : 'an interpreter'}  have commneted for line 347 */}
                      <Typography>Connecting with an Interpreter</Typography>
                      <div className="connect-anim">
                        <div className="connect-anim-1">
                          <img
                            src={Connecting_interpreter_1}
                            className="Connecting_interpreter"
                          />
                        </div>
                        <div className="connect-anim-2">
                          <div className="dootedline">
                            <marquee direction="right" scrollamount="15">
                              <i className="fa fa-caret-right"></i>
                            </marquee>
                          </div>
                          <i className="fa fa-hourglass-2 fa-spin floating-icon"></i>
                        </div>
                        <div className="connect-anim-3">
                          <img
                            src={Connecting_interpreter_2}
                            className="Connecting_interpreter"
                          />
                        </div>
                      </div>
                      <div className="two_buttons">
                        <Button
                          className="secondary"
                          variant="text"
                          onClick={() => CallStatusUpdate("cancel")}
                        >
                          <Iocn_Cancel /> Cancel
                        </Button>
                        <Popup
                          className="processingcall-modal"
                          trigger={
                            <Button className="primary" variant="text">
                              <Iocn_Callback />
                              Request Callback
                            </Button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal interpreter">
                              <div className="header">
                                {" "}
                                We will connect you to an interpreter soon.{" "}
                              </div>
                              <div className="content special-svg">
                                {" "}
                                <Icon_time />
                              </div>
                              <div className="actions">
                                <Button
                                  className="primary"
                                  onClick={() => callbackrequest()}
                                >
                                  Ok
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isvideocallstarted ? (
            <Box className="full-screen">
              <div id="meeting-container-id"></div>
            </Box>
          ) : (
            <div className="page-main">
              <div className="page-main-inner conference">
                <Box className="not_connect_call">
                  <div className="processing-call-tab-content">
                    <div className="processing-call-tab-inner">
                      <div className="calling-names-dash">
                        <div className="inte-profile">
                          <img src={Interpreter_profile} />
                        </div>
                        <div className="inte-profile-details">
                          <span>Dashing Members</span>
                          <div>
                            <span className="connecting_person">
                              {authdata.length} <img src={Multiple_userwhite} />
                            </span>
                            <span className="connecting_person_names">
                              <img src={Two_userwhite} /> {authdata.full_name}
                              {authdata.length > 1 ? "+" : ""}
                              {authdata.length > 1 ? authdata.length - 1 : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="connect-interpreter">
                        <Iocn_Cancel_call />
                        <Typography className="not_connect">
                          Couldn’t connect with{" "}
                          {authdata.full_name
                            ? authdata.full_name
                            : "an interpreter"}
                        </Typography>
                        <div className="svg_girl">
                          <Iocn_Notconnect_interpreter className="Connecting_interpreter" />
                          <Icon_crossbordercancel className="cross" />
                        </div>
                        <div className="two_buttons">
                          <Popup
                            className="processingcall-modal"
                            trigger={
                              <Button className="primary" variant="text">
                                <Iocn_Callback />
                                Request Callback
                              </Button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal interpreter">
                                <div className="header">
                                  {" "}
                                  We will connect you to an interpreter soon.{" "}
                                </div>
                                <div className="content special-svg">
                                  {" "}
                                  <Icon_time />
                                </div>
                                <div className="actions">
                                  <Button
                                    className="primary"
                                    onClick={() => callbackrequest()}
                                  >
                                    Ok
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Popup>
                          <div
                            className="dismiss"
                            onClick={() => callbackrequest()}
                          >
                            Dismiss
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GetInterpreter_page;
