import React from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../Assets/conference-user.svg";
import Call_tab from "./TabContent/Call";
import RecentCall_tab from "./TabContent/RecentCalls";
import Contact_tab from "./TabContent/Contact";
import MyGroups_tab from "./TabContent/MyGroup";



const TabsContents = (props) => {
	if(props.tabvalue==0){
		return ( <Call_tab selectedmember={props.selectedmember} doselectedmember={props.doselectedmember}/> )
	}
	else if(props.tabvalue==1){
		return ( <RecentCall_tab/> )
	}
	else if(props.tabvalue==2){
		return ( <Contact_tab/> )
	}
	else if(props.tabvalue==3){
		return ( <MyGroups_tab/> )
	}
		
}

export default TabsContents;