import React, {useState,useEffect,useRef} from 'react'
import OtpInput from "react-otp-input";
import { Typography, Paper, Grid, Box, TextField, Select, Radio, RadioGroup, FormControlLabel, Button, Modal, makeStyles, } from "@material-ui/core";
import OTP from "../../Assets/otp.png"
import web from "../../Assets/Vector.png"
 import {postDataFromApi,getDataFromApi} from '../../Service/CommonService'
 import {
    HashRouter as Router,
    Link,
    Route,
    Routes,
    useNavigate,
    useLocation
} from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  grid: {
    // backgroundColor: "grey",
    // height: "50vh",
    // textAlign: "center"
 position: "relative",
  width: "30%",
  margin: "0 auto",
  height: "auto",
  maxHeight: "70vh",
  marginTop: "calc(100vh - 55vh - 20px)",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "20px",
  border: "1px solid #999",
  overflow: "auto",

  },
  backgroundReg: {
    backgroundImage: `url(${OTP})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '1340px',
    height: '620px'

  },
  
  submit: {
    margin: theme.spacing(3, 0, 2),
    width:"303px",
    color:"#D0E1F4",
    padding:"5px"
  },
  btn_nt_blur:{
    width:"303px",
    borderRadius:"8px",
    backgroundColor:"#2760A3"
  },
  btn_blur:{
    width:"303px",
    borderRadius:"8px",
    backgroundColor:"#D0E1F4 !important"
  },
 verification:{
   textAlign:"center",
   fontSize:"14px",
   fontFamily:"mulish",
   fontWeight:"700",
   color:"#818181",
   lineHeight:"18px"
 }
}));
 
export default function OtpPhone(props) {
    const timelimit=120
    const classes=useStyles();
    const navigate=useNavigate();
    // const [otp,setotp]=useState()
    const [is_show,setShow]=useState(false);
    const [otp,setotp]=useState('')
    const [error,seterror]=useState('')
    const [newotptimer, setNewOtpTimer] = useState(timelimit);
    const [breakStartedTime, setBreakStartedTime] = useState('');
    
    // const[mobile_number,setmobile_number]=useState('')
  console.log(props)
    function displaybtn(e){
      if(e.target.value){
        setShow(true)
      }
      else{
        setShow(false)
      }
    }
    
  
 
 const mobile_number=props.mobile_number

 
  const handleverify= async()=>{
     console.log('mobile_number',mobile_number)
    console.log('otptext',otp)
   var query={mobile_number: mobile_number,otp:otp}
    // const response =await postDataFromApi('verifyOtpForLogin', query);
  //  []s
    // console.log('res',response.data)
    // console.log(response.data.code)
    // if(response.data && response.data.code){
      var data=props.registerData
      data['otp']=otp;
      const response2 = await postDataFromApi('register-user', data);
      if(response2.data && response2.data.code==1){
        localStorage.setItem('userData', JSON.stringify(response2.data.data));
        localStorage.setItem('accessToken','123')
        localStorage.setItem('successfullyLoggedIn', 'Success');
        // navigate('/home')
        navigate('/SubscriptionAfterLogin',{state:{otpPhoneResponse:response2.data.data}})
      }
      else if(response2.data.message){
        seterror(response2.data.message)
        console.log('error',seterror)
        }
    }
    // else if(response.message){
    // seterror(response.message)
    // console.log('error',seterror)
    // }
    // setotp(response.data)
    // console.log('query',query)
    // console.log('otp',response.data);
  // }
  const otpchange=(e)=>{
    setotp(e)
    console.log('otptextcange',otp)
  }
  var interval_ID=""
  const strttimer=()=>{
   setInterval(() => {
     displaytimer()
   }, 1000);
  }
  const _interval = useRef(null);
  const intervalFun = () => _interval.current;
  const clearCurrentInterval = () => {
    clearInterval(intervalFun());
  };
     const displaytimer=()=>{
      var timer=newotptimer-1
      setNewOtpTimer(timer)
     }
     const replaceCurrentInterval = (newInterval) => {
      clearCurrentInterval();
      _interval.current = newInterval;
    };
  const startTimer = () => {
   interval_ID = setInterval(() => {
     // var timercount=newotptimer-1
     var timercount=newotptimer-1
     setNewOtpTimer(timercount)
     if(newotptimer<0){
       timercount=timelimit
     }
     timercount=secondsToTime(timercount)
     setBreakStartedTime(timercount)
   }, 1000);
 
   replaceCurrentInterval(interval_ID);
 }

 useEffect(() => {
  startTimer()
 })
 const secondsToTime=(secs)=>{
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    "h": hours > 9 ? hours : '0'+hours,
    "m": minutes > 9 ?  minutes : '0'+minutes,
    "s":  seconds > 9 ? seconds : '0'+seconds,
  };
  return obj;
}
 

 const resendOtp=()=>{
  seterror('')
  var query={mobile_number: mobile_number}
  const res =postDataFromApi('sendOtpForLogin', query);
     console.log('resend',res)
     setNewOtpTimer(timelimit)
 }

  
  return (
   
    //  <div className={classes.paper}>
    <div className={classes.backgroundReg}>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={3}
        >
          

 <Grid className='header-otp'>
 <div style={{
          whiteSpace: "nowrap",
          textAlign: "center",
          marginTop:"4%"
        }}>
          <img src={web} alt="Logo" style={{ width: '78px', height: '61px' }} />
          <div style={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "22px", color: "#2760A3" }}>Sign</span>
            <span style={{ display: "center", fontSize: "22px", color: "#FF9D00", fontWeight: "bold" }}>Able</span>
          </div>
        </div>
        <Grid item xs={12} textAlign="center" >
            <div className='container-otp' >
              
              <Typography className={classes.verification}>verification</Typography>
              <div className='enter-otp-text'>
              <Typography >
                Enter OTP sent on your mobile number
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid >
            <Grid item  justify="center" > 
            <div className='otp-input'>
              <OtpInput
                onChange={(e)=>otpchange(e)}
                inputStyle={{
                  
                  width: "2rem",
                  height: "3rem",
                  margin: "0 1rem",
                  fontSize: "2rem",
                  // color:"#2760A3",
                  borderBlockWidth:"1px",
                  borderBottomColor:"blue",
                  borderLeftColor:"transparent",
                  borderRightColor:"transparent",
                  borderTopColor:"transparent"
                //   borderRadius: 4,
                //   border: "1px solid rgba(0,0,0,0.3)"
                }}
                focusStyle={{
                  
                  outline: "none"
                }}
              />
              </div>
            </Grid>
            <div className='verify-button'>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={  classes.btn_nt_blur }
                onClick={
                   ()=>  handleverify()
                      //  navigate('/home')
                }
              >
                Verify
              </Button>
            
              </div>
              {error ? <span className='Responseerr'>{error}</span> : ""}
              <div  style={{marginTop:"3.5%"}}>
              <Grid className="otptimer">
               
              <span>{newotptimer> 0  && breakStartedTime? breakStartedTime.m+':'+breakStartedTime.s : ''}</span>
                 <Button
                type="submit"
                fullWidth
                variant="text"
               color="primary"
               disabled={newotptimer> 0}
              className='resend-btn'
              onClick={
                ()=>  resendOtp()
             }
              >
                Resend OTP
              </Button>
              </Grid> 
              </div>
        
  </Grid>
  </Grid>
 </Grid>












































{/*          
          <Grid item xs={12} textAlign="center" marginTop="8px">
            <Paper elevation={0}>
              <Typography style={{fontSize:"16px",color:"#2760A3",textAlign:"center"}}>
                Enter OPT sent on your mobile number
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item spacing={3} justify="center">
              <OtpInput
                separator={
                  <span>
                    {/* <strong>.</strong> */}
                  {/* </span>
                }
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 1rem",
                  fontSize: "2rem",
                  borderBlockWidth:"1px",
                  borderBottomColor:"blue",
                  borderLeftColor:"transparent",
                   borderRightColor:"transparent",
                    borderTopColor:"transparent"
                //   borderRadius: 4,
                //   border: "1px solid rgba(0,0,0,0.3)"
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={
                   ()=>  handleverify(),
                       navigate('/home')
                }
              >
                Verify
              </Button>
              <Grid>
                 <Button
                type="submit"
                fullWidth
                variant="text"
               color="primary"
                // className={classes.submit}
              >
                Resend OTP
              </Button> 
        </Grid>
            </Grid>
          </Grid> */} 
        {/* </Grid>
      </div> */}
      // </div>
  )
}
