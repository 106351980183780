import React,{useEffect,useState} from "react";
import Popup from 'reactjs-popup';
import {
	Routes,
	Route,
	NavLink,
	HashRouter,
	useNavigate
} from "react-router-dom";
import swal from "sweetalert"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SearchIcon from "../../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../../Assets/conference-user.svg";
import Conference_user_remove from "../../../Assets/conference-user-remove.svg";
import Two_user from "../../../Assets/two_user.svg";
import Two_userwhite from "../../../Assets/two-user-white.svg";
import Multiple_userwhite from "../../../Assets/multiple-user-white.svg";
import Interpreter_profile from "../../../Assets/interpreter-profile.svg";
import Connecting_interpreter_1 from "../../../Assets/connecting-interpreter-1.svg";
import Connecting_interpreter_2 from "../../../Assets/connecting-interpreter-2.svg";
import {ReactComponent as Iocn_Callback} from "../../../Assets/callback.svg";
import {ReactComponent as Iocn_Cancel}  from "../../../Assets/cancel.svg";
import Connecting from "../../../Assets/connecting.svg";
import {ReactComponent as Icon_whitevideocall} from "../../../Assets/white-video-call.svg";
import {ReactComponent as Icon_teluguicon} from "../../../Assets/telugu-icon.svg";
import {ReactComponent as Icon_time} from "../../../Assets/time.svg";
import {ReactComponent as Icon_closegrey} from "../../../Assets/close-grey.svg";
import {getToken,createMeeting,sdkAPIKey,callSetup,startTimerToGetCallDetails,custoclearinterval,BaseUrl,CallStatusUpdateData} from '../../../Service/videoskp';
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import {getprofile,getAuth} from '../../../Service/Helper';
import {postDataFromApi,getDataFromApi,putDataFromApi} from '../../../Service/CommonService';
import NotconnectedCall from './NotConnectedCall'
import $ from 'jquery';
const ProccessingCall = (props) => {
	const navigate =useNavigate()
	const[token,settoken]=useState([])
	const [is_notconnected,setNotconnected]=useState(false)
	const[MeetingData,setMeetingData]=useState([])
	const[MeetingId,setMeetingId]=useState('')
	const authdata=getAuth()
	const userid=authdata['_id'];
	const [intervalId, setIntervalId] = useState(false);
	const [isCallReceived,setisCallReceived] = useState(false)
	const [CallResponseData,setCallResponseData] = useState({})
	const [newIntervalId,setnewIntervalId]=useState('');
	const [Showlangpopup,setShowlangpopup] = useState(false);
	const [Showintavailbale,setShowintavailbale] = useState(false);
	const [formdata,setFormData]=useState({language:""})
	const [languages,setlanguages]=useState([])
	const [Showerror,setShowerror] = useState(false);
	
	var singlenewIntervalId  ="";
	var processed=false;

	const getlanguages = async () => {
	    var query = "model=LanguageMasterData"
	    const response = await postDataFromApi('all/getAllMaster', query);
	    if(response.data.code && response.data.data!=null){
	    	setlanguages(response.data.data);
	    }

	}
    
    const chosedlanguage= async (e) => {
		/*setProccessed(true)*/
		if(formdata.language=="" || formdata.language=='default'){
		   setShowerror(true)
		}
		if(formdata.language && formdata.language!='default'){
			console.log(formdata.language)
		    setShowlangpopup(false);
		    gettoken()
		}
	}
    const Languagecancel=()=>{
		navigate('/Home')
	 }
	const closeModal =()=>{
		setShowlangpopup(false);
		setShowintavailbale(false)
	}
	function changedropdownvalue(e,type){
		setShowerror(false)
		console.log(type)
		console.log(e.target.value)
        if(e){
           var value=e.target.value
        }else{
            var value=""
        }
        setFormData((formData) => ({
            ...formData,
            [type]:value,
        }));
    }


	const gettoken = async () => {
		var query = ""
		const token = await getToken();
		console.log('get token',token)
		if(token && token!=null){
			settoken(token);
			getMeetingData(token);

		}
	}
	const getMeetingData = async (token) => {
		var query = ""
		const response = await createMeeting(token);
		console.log('response',response)
		if(response &&response!=null){
			setMeetingData(response)
			setMeetingId(response.meetingId)
			console.log(response.meetingId)
	          //startmeeting(response.meetingId)
	          settingCall(response)
	      }


	  }

	  const settingCall = async (data) => {
		// (responseData[i].user_type !== 1)

	  	// var contactDetailsArray=[];
		var PartcipantsDetailsArray=[]
	  	// var contactdetails = props.selectedmember.contactdetails
	  	console.log(ParticipantsDetails)
		var ParticipantsDetails=props.callAttendeeDetails
		// PartcipantsDetailsArray=[{
		// 	mobile_number:authdata['mobile_number'],
	  	// 	name:authdata['first_name'],

	  	// }]
		console.log('callAttendeeDetails==',props.callAttendeeDetails)
		var newParticipantsdetails=[];
		var iniatordetails=[]
	  	props.callAttendeeDetails.map((contact,i)=>{
			var ct={
				  mobile_number:contact.user_docs[0].mobile_number,
				  name:contact.user_docs[0].first_name+" "+contact.user_docs[0].last_name,
				
			  };
			if(contact.user_type!==1){
				newParticipantsdetails.push(ct)
			 }
			console.log('participantsDetails==',newParticipantsdetails)
	  		// else{
			// 	PartcipantsDetailsArray.push(ct)
			// 	iniatordetails=contact.user_docs[0]
			// }
			  console.log('contact--->',contact.user_docs[0].mobile_number)
			//   var ct={
			// 	mobile_number:"99898589685",
	  		// 	name:"Arti Panday",
	  		// };
                // var ct=[];
                
                // ct['mobile_number']=ParticipantsDetails.mobile_number
	            // ct['name']=ParticipantsDetails.contact_name
			 
	        })

	  

	  	var inputData = JSON.stringify({
	  		"user_id": userid,
	  		"call_type": "1",
	  		"language_id": formdata.language ? formdata.language : authdata['language_id'],
	  		"purpose_id": "1",
	  		"meetingDetails": data,
	  		"contactDetails": newParticipantsdetails,
	  		"user_type": authdata['user_type'],
	  		"individual_user_type": authdata['user_category'],
	  		"is_same_gender": "0",
	  		"initiator_gender": authdata['gender'],
	  		"initiatorFullName": authdata['first_name'],
			"source":"web"
	  	})
		// if(iniatordetails){
			// inputData['user_type']=iniatordetails['user_type']
			// inputData['individual_user_type']=iniatordetails['user_category']
			// inputData['initiator_gender']=iniatordetails['gender']
			// inputData['initiatorFullName']=iniatordetails['first_name']
		// }
	  	console.log('inputData',inputData)

	  	/* call setup API */

	  	const callResponse = await callSetup(inputData);
	  	setCallResponseData(callResponse)
	  	console.log('callResponse',callResponse);
		//console.log('callResponse',callResponse.data.meetingId)
        //callResponse.code=2
		if(callResponse && callResponse.code==1){
			if(!intervalId){
				setTimeout(function () {
					if(!isCallReceived && !processed){
						CallStatusUpdateData(callResponse.data._id,'7','6')	
						clearInterval(singlenewIntervalId);
						setIntervalId(true);
						props.setNotconnected(true)
					}
				}, 30000);
				singlenewIntervalId=setInterval(function () {
					console.log('every 3 seconds')
					startTimer(callResponse)
				}, 3000);
				setnewIntervalId(singlenewIntervalId)
			}
			//clearInterval(singlenewIntervalId);
			/*if (intervalId) {
		      clearInterval(singlenewIntervalId);
		      
		      console.log("  cc clear")
		      
		  }*/


		}
		else if(callResponse.code == 2){
              setShowlangpopup(true)
              setShowintavailbale(false)
              if(callResponse.data.language_id && callResponse.data.language_id!=0){
              	 setFormData((formData) => ({
		            ...formData,
		            ['language']:callResponse.data.language_id,
		        }));
              }
		}
		else if(callResponse.code == 3){
                // setShowintavailbale(true)
                setShowlangpopup(false)
				swal({
					text: "No Interpreter available",
					icon: "error",
				  }).then(function () {
					navigate("/home");
				  });
		}
		else{
			// alert(callResponse.message)
			// clearInterval(singlenewIntervalId);
			// navigate("/home")

			if (callResponse.message === "User Not found") {
				clearInterval(singlenewIntervalId);
				swal({
				  text: "Your are blocked. Please contact admin team.",
				  icon: "error",
				}).then(function () {
				  navigate("/home");
				});
			  } else {
				clearInterval(singlenewIntervalId);
				swal({
				  text: callResponse.message,
				  icon: "error",
				}).then(function () {
				  navigate("/home");
				});
			  }

		}
		
	}	
	
	const startTimer = async (data) => {
		/*const response1 = await getDataFromApi('getcallDetails/'+MeetingId);
		console.log('console.log(response)',response1)*/
		const response = await startTimerToGetCallDetails(data.data._id, authdata['first_name'],authdata['user_type'], token);
		console.log('startTimer',response)

		if (response && response.data !== "data not found") {
			console.log(newIntervalId);
			// response.data.status = 2
			if (response.data.status == 2) {
				if(!processed){
					processed=true;
					clearInterval(newIntervalId);
					setIntervalId(true);
					CallStatusUpdateData(data.data._id,'3','3')
					startmeeting(data.data.meetingId,data.data._id)
					setisCallReceived(true)
				}
			}
		}


	}	
	const startmeeting=(meetingId,callSetupid)=>{
		localStorage.setItem('callSetupid', callSetupid)
	    //here call start meeting api before initiate meeting our own api will be call
	    const config = {
	    	name: authdata['first_name'],
	    	meetingId: meetingId,
	    	apiKey:sdkAPIKey,
	    	// joinScreen: {
	    	// 	visible: false,

	    	// },
	    	containerId: "meeting-container-id",
	    	micEnabled: true,
	    	webcamEnabled: true,
	    	participantCanToggleSelfWebcam: true,
	    	participantCanToggleSelfMic: true,
            chatEnabled: true,
	    	screenShareEnabled: true,
	    	canChangeLayout: true,
			redirectOnLeave:window.location.origin,
	    	// redirectOnLeave:window.location.origin+'/InterpreterFeedbackform',
	    	joinScreen: {
	    	visible: false,
	    	},
	    };

	    const meeting = new VideoSDKMeeting();
	    meeting.init(config);
	   	$(".full-screen").insertAfter(".page-main");
	    props.setisvideocallstarted(true)
		setNotconnected(true)

	}

	const CallStatusUpdate = async (buttonType)=>{

		clearInterval(newIntervalId);
		setIntervalId(true);

		if(buttonType=='cancel'){
			console.log('CallResponseData as',CallResponseData)
			const response = await CallStatusUpdateData(CallResponseData.data._id,'6','6');
			console.log('cancel',response)
			if(response && response.code){
				props.setNotconnected(false)
				props.setProccessed(false)
				props.setSelected(false)
				clearInterval(singlenewIntervalId);

			}

		}else if(buttonType=='callback'){
			const response = await CallStatusUpdateData(CallResponseData.data._id,'5','6');
			console.log(response)
		}
	}
	useEffect(() => {
		gettoken();
		// getlanguages();
	    /*document.body.className = 'videocall';
	    return () => { document.body.className = ''; }*/
	    
	}, []);

	function callbackrequest(){
		clearInterval(newIntervalId);
		setIntervalId(true);
		navigate('/Home')
	}
	
	if(!intervalId && !props.isvideocallstarted){
		return (
			<div className="page-main">
			<div className="page-main-inner conference-processing-callhistory">
			<div className="processing-call-tab-content">
			<div className="processing-call-tab-inner">
			<div className="calling-names-dash">
			<div className="inte-profile">
			<img src={Interpreter_profile} />
			</div>
			<div className="inte-profile-details">
			<span>{authdata.first_name+" "+authdata.last_name}</span>
			<div>
			{/* {props.selectedmember.member.length} */}
			<span className="connecting_person">1 <img src={Multiple_userwhite} /></span>
			{/* <span className="connecting_person_names"><img src={Two_userwhite} /> {props.selectedmember.contactdetails[0].contact_name}{props.selectedmember.member.length > 1 ? '+' : ''}{props.selectedmember.member.length > 1 ?  props.selectedmember.member.length - 1 : ''}</span> */}
			</div>
			</div>
			</div>
			<div className="connect-interpreter">
			<img src={Connecting} />
			<Typography>Connecting with an interpreter</Typography>
			<div className="connect-anim">
			<div className="connect-anim-1">
			<img src={Connecting_interpreter_1} className="Connecting_interpreter" />
			</div>
			<div className="connect-anim-2">
			<div className="dootedline" >
			<marquee direction="right" scrollamount="15">
			<i className="fa fa-caret-right"></i>
			</marquee>
			</div>
			<i className="fa fa-hourglass-2 fa-spin floating-icon"></i>
			</div>
			<div className="connect-anim-3">
			<img src={Connecting_interpreter_2} className="Connecting_interpreter" />
			</div>
			</div>
			<div className="two_buttons">
			<Button className="secondary" variant="text" onClick={()=>CallStatusUpdate('cancel')} ><Iocn_Cancel /> Cancel</Button>
			<Popup className="processingcall-modal"
			trigger={<Button className="primary" variant="text"  ><Iocn_Callback />Request Callback</Button>
		}
		modal
		nested
		>
		{close => (
			<div className="modal interpreter">
			<div className="header"> We will connect you to an
			interpreter soon. </div>
			<div className="content special-svg">
			{' '}
			<Icon_time />
			</div>
			<div className="actions">
			<Button className="primary" onClick={()=>callbackrequest()}>Ok</Button>
			</div>
			</div>
			)}
			</Popup>
            <Popup className="processingcall-modal language"
							modal
							open={Showlangpopup}
							closeOnDocumentClick 
							onClose={closeModal}
						>
						    <div className="modal interpreter">
							   <button className="close" onClick={()=>Languagecancel()}>
							      <Icon_closegrey />
							   </button>
							   <div className="header"> Select language </div>
							   <div className="content special-svg">
							      {' '}
							      <div className="form-div">
							         <label>Language</label>
							         <select
							            onChange={(e) =>changedropdownvalue(e,'language')}
							            
							            name="language"
							            className="language"
							            id="language"
							            value={formdata.language}
							            >
							            <option value="default" selected disabled>Select language of the person</option>
							            {
							            languages.map(function(language,i){
							            return (
							            <option value={language._id}>{language.language}</option>
							            )
							            })
							            }
							         </select>
							      </div>
							   </div>
							    <Typography className="customerror">{Showerror ? 'please select language of the person':''}</Typography>
							   <div className="actions">
							      <Button className="primary" onClick={()=>chosedlanguage()}>Done</Button>
							   </div>
							</div>
						</Popup>
			            <Popup className="processingcall-modal no-interpreter"
							modal
							open={Showintavailbale}
							closeOnDocumentClick 
							onClose={closeModal}
						>
							<div className="modal interpreter">
							   <button className="close" onClick={closeModal}>
							      <Icon_closegrey />
							   </button>
							   <div className="header"> No Interpreter Availabale </div>
							</div>
						</Popup>
			</div>
			</div>
			</div>
			</div>
			</div>
			</div>
			)	
	}else{
		return (
	 <>{is_notconnected ?
		<Box className="full-screen"><div id="meeting-container-id"></div></Box>
		:
		<NotconnectedCall  />
		
	}
	</>
	)	
}
}

export default ProccessingCall;