import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
} from "@material-ui/core";
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";  
import Flag_Ind from "../../Assets/call-a-person-ind-flag.svg";
import Submit_btn from "../../Assets/call-a-person-main-form-v-call.png";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../../Service/CommonService";
import { getprofile, getAuth } from "../../Service/Helper";
import { Multiselect } from "multiselect-react-dropdown";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import Select from "react-select";

const Profile_page = () => {
  const authdata = getAuth();
  const userid = authdata["_id"];
  const [formdata, setFormData] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    mobile_number: "",
    email: "",
    language_id: "",
    state_name: "",
    city_name: "",
    user_type: "",
    user_category: "",
    role: "",
    signable_user_type: "",
  });
  const [value, setValue] = React.useState(null);
  const [languages, setlanguages] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [profile, setprofile] = useState([]);
  const options = [{ name: "india", id: "23" }];
  const [stateoptions, setstateoptions] = useState([]);
  const [cityoptions, setcityoptions] = useState([]);
  const [langoptions, setlangoptions] = useState([]);
  const [is_loaded, setis_loaded] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [copyText, setCopyText] = useState('User ID');

  const displayState = [];
  const displaycity = [];
  useEffect(() => {
    getlanguages();
    getstates();

    getprofile();
  }, []);

  const getprofile = async () => {
    var query = "";
    const response = await getDataFromApi("find/userProfile/" + userid, query);
    console.log("profile", response);
    if (response.data.code && response.data.data != null) {
      setprofile(response.data.data[0]);
      // console.log('response.data.data[0].state._id',response.data.data[0].state._id)
      console.log("userid", userid);
      setFirstName(response.data.data[0].first_name);
      setLastName(response.data.data[0].last_name);
      setEmail(response.data.data[0].email);
      setDateOfBirth(response.data.data[0].dob);
      setCopyText(response.data.data[0].first_name)

      setFormData((formData) => ({
        ...formData,
        ["first_name"]: response.data.data[0].first_name,
        ["last_name"]: response.data.data[0].last_name,
        ["dob"]:
          response.data.data[0].dob &&
          response.data.data[0].dob !== "NaN-NaN-NaN"
            ? response.data.data[0].dob
            : "-",
        ["gender"]: response.data.data[0].gender,
        ["mobile_number"]: response.data.data[0].mobile_number,
        ["email"]: response.data.data[0].email,
        ["state_name"]: response.data.data[0].state
          ? response.data.data[0].state._id
          : "",
        ["city_name"]: response.data.data[0].city,
        ["user_type"]: response.data.data[0].user_type,
        ["user_category"]: response.data.data[0].user_category,
        ["role"]: response.data.data[0].role,
        ["signable_user_type"]: response.data.data[0].signable_user_type,

        //['language_id']:response.data.data[0].language_id,
        ["language_id"]: response.data.data[0].language_id,
      }));
      getcities(
        response.data.data[0].state ? response.data.data[0].state._id : ""
      );
      setTimeout(function () {
        setis_loaded(true);
      }, 1000);
    }
  };
  const getlanguages = async () => {
    var query = "model=LanguageMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    console.log("language", response);
    if (response.data.code && response.data.data != null) {
      setlanguages(response.data.data);
      var langopts = [];
      response.data.data.map((lang, i) => {
        var lg = [];
        lg["value"] = lang._id;
        lg["label"] = lang.language;
        langopts.push(lg);
      });
      setlangoptions(langopts);
      console.log("states data", response);
    }
  };

  const getstates = async () => {
    var query = "model=StateMasterData";
    const response = await postDataFromApi("all/getAllMaster", query);
    if (response.data.code && response.data.data != null) {
      setstates(response.data.data);
      var stateopts = [];
      response.data.data.map((state, i) => {
        var st = [];
        st["value"] = state._id;
        st["label"] = state.state_name;
        stateopts.push(st);
      });
      setstateoptions(stateopts);
      console.log("state", stateopts);
    }
  };
  // states.map(function (state) {
  // 	console.log('displayState',displayState)
  // 	return displayState.push({ value: state._id, label: state.state_name });

  // 	 });
  const getcities = async (stateid) => {
    var query = "model=CityMasterData&state_id=" + stateid;
    const response = await postDataFromApi("all/getAllMaster", query);
    console.log(response);
    if (response.data.code && response.data.data != null) {
      setcities(response.data.data);
      var cityopts = [];
      response.data.data.map((city, i) => {
        var ct = [];
        ct["value"] = city._id;
        ct["label"] = city.city_name;
        cityopts.push(ct);
      });
      setcityoptions(cityopts);
      console.log("city data", response);
    }
  };
  const multiselectchange = (e, type) => {
    // console.log(e[0]['id'])
    if (type == "state_name") {
      setFormData((formData) => ({
        ...formData,
        ["state_name"]: e[0]["value"],
        ["city_name"]: "",
      }));
      getcities(e[0]["value"]);
    } else {
      setFormData((formData) => ({
        ...formData,
        [type]: e[0]["value"],
      }));
    }
  };

  const dropdownChange = (selectedOption, type = "") => {
    //   multiselectchange(selectedOption.value);
    console.log(`Option selected:`, selectedOption.value);
    if (type == "state_name") {
      setFormData((formData) => ({
        ...formData,
        ["state_name"]: selectedOption.value,
        ["city_name"]: "",
      }));
      getcities(selectedOption.value);
    } else {
      setFormData((formData) => ({
        ...formData,
        [type]: selectedOption.value,
      }));
    }
  };

  function getSelectedItem(id, options) {
    console.log("iod", id);
    console.log("options", options);
    console.log("iod", id);
    const item = options.find((opt) => {
      if (opt.value == id) return opt;
    });
    console.log("item", item);
    var res = item ? [item] : [];
    return res;
  }
  const updateprofile = async (e) => {
    if(firstName == ""){
      swal({
        text: "First Name Is Mandatory ",
        icon: "error",
      });
      return
    }
    if(lastName == ""){
      swal({
        text: "Last Name Is Mandatory ",
        icon: "error",
      });
      return
    }
    if(email){
      const inputValue = email
      const onlyCharactersRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!onlyCharactersRegex.test(inputValue)) {
        swal({
          text: "Invalid Email Address",
          icon: "error",
        });
      }
      return
    }
    //http://localhost:8000/api/update/userProfile
    var newformdata = {
      id: userid,
      modelName: "user_master",
      data: {
        user_type: formdata.user_type,
        user_category: formdata.user_category,
        mobile_number: formdata.mobile_number,
        // first_name: formdata.first_name,
        // last_name: formdata.last_name,
        // email: formdata.email,
        // dob: formdata.dob,
        first_name: firstName,
        last_name: lastName,
        email: email,
        dob: dateOfBirth,
        gender: formdata.gender,
        state: formdata.state_name,
        city: formdata.city_name,
        role: formdata.role,
        signable_user_type: formdata.signable_user_type,
        language_id: formdata.language_id,
      },
    };
    var response = "";
    console.log("formdata", newformdata);
    response = await postDataFromApi("update/userProfile", newformdata);
    if (response.data.code) {
      getprofile();
      swal({
        text: response.data.message,
        icon: "success",
      });
      console.log(response.data.message);
    } else {
      swal({
        text: response.data.message,
        icon: "error",
      });
      console.log(response.data.message);
    }
  };
  /*const handleSubmit = async (e) => {
	   	console.log('check',formdata)
	   }*/
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),

    control: (_, { selectProps: { width } }) => ({
      width: width,
    }),
  };

  function nameChangeFunc(e) {
      const inputValue = e.target.value;
      const onlyCharactersRegex = /^[A-Za-z]+$/;
      if (inputValue === "" || onlyCharactersRegex.test(inputValue)) {
        setFirstName(inputValue);
      } else {
        swal({
          text: "Only charactors are allowed",
          icon: "error",
        });
      }
  }
  function lastNameChangeFunc(e) {
    // setLastName(e.target.value);
    const inputValue = e.target.value;
      const onlyCharactersRegex = /^[A-Za-z]+$/;
      if (inputValue === "" || onlyCharactersRegex.test(inputValue)) {
        setLastName(inputValue);
      } else {
        swal({
          text: "Only charactors are allowed",
          icon: "error",
        });
      }
  }
  function emailChangeFunc(e) {
    setEmail(e.target.value);
  }
  function dobChangeFunc(e) {
    setDateOfBirth(e.target.value);
  }
  const copyToClipboard = () => {
    copy(copyText);
    // alert(`You have copied "${copyText}"`);
 }

  return is_loaded ? (
    <div className="page-main">
      <div className="page-main-inner profile">
        <Box>
          <Grid container spacing={3} className="main-grid">
            <Grid item md={12} className="edit-profile">
              <div
                className="box-shadow text-center radius-8px"
                style={{ height: "100%" }}
              >
                <div className="call-person-tab-header">
                  <h3>Profile</h3>
                  <Grid container>
                    <Grid item>
                      {/* <Button variant="outlined" color="primary" className="copyButton" endIcon={<SendIcon />} onClick={copyToClipboard}>{copyText}</Button> */}
                      <Button variant="outlined" href="https://www.google.com/" target="_blank" className="copyButton" endIcon={<ContentCopyIcon />} onClick={copyToClipboard}>{copyText}</Button>
                    </Grid>
                  </Grid>
                </div>
                <div className="call-person-form-main">
                  <div className="call-person-form-main-inner">
                    <form
                      className="profile_form"
                      onSubmit={FormData.handleSubmit}
                    >
                      <Grid container spacing={3} className="main-grid">
                        <Grid item md={6}>
                          <div className="form-div">
                            <label>User Type</label>
                            <input
                              type="text"
                              name="usertype"
                              className="usertype textColor"
                              id="usertype"
                              placeholder="Enter User Type"
                              value="Individual"
                              disabled
                            />
                          </div>
                          <div className="form-div">
                            <label>First Name</label>
                            <input
                              type="text"
                              name="firstname"
                              className="firstname textColor"
                              id="firstname"
                              placeholder="Enter firstname"
                              // value={formdata.first_name}
                              value={firstName || ""}
                              onChange={nameChangeFunc}
                              // disabled
                            />
                          </div>
                          <div className="form-div">
                            <label>DOB</label>
                            <input
                              // type="text"
                              type="date"
                              name="DOB"
                              className="DOB textColor"
                              id="DOB"
                              placeholder="Enter DOB"
                              value={dateOfBirth || ""}
                              onChange={dobChangeFunc}
                              // value={formdata.dob || ""}
                              // disabled
                            />
                          </div>
                          <div className="form-div">
                            <label className="img-label">
                              <img src={Flag_Ind} />
                              <input
                                type="text"
                                name="phone-number"
                                className="phone-number textColor"
                                id="phone-number"
                                placeholder="+91  Enter phone number"
                                value={formdata.mobile_number}
                                disabled
                              />
                            </label>
                          </div>
                          <div className="form-div">
                            <label>State</label>

                            {/* <Multiselect
							                          options={states && states.length > 0 ? stateoptions : []} 
													  className="single_profile_font"
							                          onSelect={(e) => multiselectchange(e, 'state_name')}
							                          selectedValues = { getSelectedItem(formdata.state_name,stateoptions) }
													  avoidHighlightFirstOption={true}
							                          displayValue="label"
							                          id='state'
							                          name='state'
							                          singleSelect='true'
							                          error={Boolean(FormData.touched.state_name && FormData.errors.state_name)}
							                          helperText={FormData.touched.state_name && FormData.errors.state_name}
							                          
							                          value={formdata.state_name}
							                          placeholder='Select State'
							                          
							                        /> */}
                            <Select
                              options={stateoptions}
                              isSearchable={true}
                              // style={{boder:"none"}}
                              onChange={(selectedOption) =>
                                dropdownChange(selectedOption, "state_name")
                              }
                              // value={stateoptions}
                              value={getSelectedItem(
                                formdata.state_name,
                                stateoptions
                              )}
                              className="css-b62m3t-container textColor"
                              placeholder="Select State"
                            />
                          </div>
                          {formdata.user_category == 2 ? (
                            <div className="form-div">
                              <label>Language</label>
                              {/* <Multiselect
							                          options={languages && languages.length > 0 ? langoptions : []} 
													  className="single_profile_font"
							                          onSelect={(e) => multiselectchange(e, 'language_id')}
							                          selectedValues = { getSelectedItem(formdata.language_id,langoptions) }
							                          displayValue="label"
							                          id='language_id'
							                          name='language_id'
							                          singleSelect='true'
							                          avoidHighlightFirstOption={true}
							                        //   error={Boolean(formdata.touched.language_id && formdata.errors.language_id)}
							                        //   helperText={formdata.touched.language_id && formdata.errors.language_id}
							                          placeholder='Select language'
							                          
							                        /> */}
                              <Select
                                options={langoptions}
                                isSearchable={true}
                                // style={{boder:"none"}}
                                onChange={(selectedOption) =>
                                  dropdownChange(selectedOption, "language_id")
                                }
                                // value={stateoptions}
                                value={getSelectedItem(
                                  formdata.language_id,
                                  langoptions
                                )}
                                className="css-b62m3t-container"
                                placeholder="Select State"
                                //	styles={customStyles}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item md={6}>
                          <div className="form-div">
                            <label>User Cateory</label>
                            <input
                              type="text"
                              name="usercategory"
                              className="usercategory textColor"
                              id="usercategory"
                              placeholder="Enter User Cateory"
                              // value="I can hear"
                              value={
                                formdata.user_category == 1 ? "Deaf" : "Hearing"
                              }
                              disabled
                            />
                          </div>
                          <div className="form-div">
                            <label>Last Name</label>
                            <input
                              type="text"
                              name="lastname"
                              className="lastname textColor"
                              id="lastname"
                              placeholder="Enter lastname"
                              // value={formdata.last_name || ""}
                              // disabled
                              value={lastName || ""}
                              onChange={lastNameChangeFunc}
                            />
                          </div>
                          <div className="form-div">
                            <label>Gender</label>
                            <input
                              type="text"
                              name="gender"
                              className="gender textColor"
                              id="gender"
                              value={formdata.gender || ""}
                              disabled
                            />
                          </div>
                          <div className="form-div">
                            <label>Email</label>
                            <input
                              type="email"
                              name="email"
                              className="email textColor"
                              id="email"
                              placeholder="Enter Email"
                              // value={formdata.email || ""}
                              // disabled
                              value={email || ""}
                              onChange={emailChangeFunc}
                            />
                          </div>
                          <div className="form-div">
                            <label>City</label>
                            {/* <Multiselect
													className="single_profile_font"
								                        options={cities && cities.length > 0 ? cityoptions : []} // Options to display in the dropdown
								                          
								                          onSelect={(e) => multiselectchange(e, 'city_name')}
								                          // onRemove={this.onRemove} 
								                          displayValue="label"
								                          id='city'
								                          name='city'
								                          singleSelect='true'
								                          selectedValues = { getSelectedItem(formdata.city_name,cityoptions) }
														  avoidHighlightFirstOption={true}
								                          // placeholder='select city'
								                          error={Boolean(FormData.touched.city_name && FormData.errors.city_name)}
								                          helperText={FormData.touched.city_name && FormData.errors.city_name}
								                          
								                          placeholder='Select City'
								                          
								                    /> */}
                            <Select
                              options={cityoptions}
                              isSearchable={true}
                              // style={{boder:"none"}}
                              onChange={(selectedOption) =>
                                dropdownChange(selectedOption, "city_name")
                              }
                              // value={cityoptions}
                              value={getSelectedItem(
                                formdata.city_name,
                                cityoptions
                              )}
                              className="css-b62m3t-container textColor"
                              placeholder="Select State"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Button
                        className="primary"
                        variant="text"
                        style={{ marginTop: "5%" }}
                        type="button"
                        onClick={(e) => updateprofile(e)}
                      >
                        Update
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  ) : (
    ""
  );
};

export default Profile_page;
