import React, { useState, useEffect } from "react";
import { ReactComponent as Vector } from "../../Assets/icon.svg";
import Input from "../../CommonComponent/Input";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import Otp from "../../Components/LoginComponent/Otp";
import { postDataFromApi, getDataFromApi } from "../../Service/CommonService";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import style from "../../Assets/css/style.css";
import {
  Routes,
  Route,
  NavLink,
  HashRouter,
  useNavigate,
} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "center",
    paddingHorizontal: 40,
  },
  inputContainer: {
    backgroundColor: "#FFFFFF",
    paddingHorizontal: 15,
    borderRadius: 8,
    borderColor: "#D0E1F4",
    border: "2px solid ",
    borderWidth: 2,

    // height: 60,
    // width: '80%'
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(42, 84, 250)",
    width: "136px",
    height: "86px",
    borderRadius: "5px",
    paddingTop: "2px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "45px",
    marginRight: "8px",
    marginLeft: "6px",
  },
  inputLabel: {
    paddingTop: 5,
    fontSize: 13,
    color: "#5490D6",
    marginBottom: "2px",
    textAlign: "center",
    marginTop: "30px",
  },
  input: {
    color: "#515151",
    fontWeight: "bold",
    fontSize: 14,
    flex: 1,
    // width:"397px",
    textAlign: "center",
  },
  mainDiv: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "white",
    opacity: "1",
    padding: "20",
    height: "341px",
    width: "825px",
  },
  div1: {
    height: "190px",
    width: "343px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  div2: {
    height: "190px",
    width: "343px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    position: "absolute",
    width: "50px",
    height: "60px",
    top: "-39px",
    marginLeft: "38px",

    // zIndex: -1,
  },

  signText: {
    fontWeight: "bolder",
    fontSize: "38px",
    color: "#2760A3",
    textAlign: "center",
  },
  ableText: {
    display: "center",
    fontSize: "38px",
    color: "#FF9D00",
    fontWeight: "bolder",
    textAlign: "center",
  },
  btn_nt_blur: {
    width: "302px",
    borderRadius: "8px",
    backgroundColor: "#2760A3",
  },
  btn_blur: {
    width: "302px",
    borderRadius: "8px",
    backgroundColor: "#D0E1F4 !important",
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Login() {
  const [isOpen, setIsOpen] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const phoneRegExp =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
  const [is_show, setShow] = useState(false);
  const navigate = useNavigate();
  let validationSchema = Yup.object({
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Invalid Phone Number")
      .max(10),
  });

  const FormData = useFormik({
    initialValues: {
      mobile_number: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (value) => {
      var query = { mobile_number: value.mobile_number };
      const response = await postDataFromApi("sendOtpForLogin", query);

      if (response.data && response.data.code) {
        setIsOpen(!isOpen);
      } else {
        setshowerror(true);
        seterrormessage(response.data.message);
        console.log("responses", response);
      }
    },
  });
  function displaybtn(e) {
    if (e.target.value.length == 10) {
      setshowerror(false);
      seterrormessage("");
      if (phoneRegExp.test(e.target.value)) {
        setShow(true);
      } else {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  }
  const classes = useStyles();
  const checkredirectpage = () => {
    // const pathname=window.location.pathname
    // var ex=pathname.split('/');
    // if(ex[ex.length-1]=='InterpreterFeedbackform'){
    //   navigate('/InterpreterFeedbackform')
    //   // window.history.replaceState(null, '', '/#/InterpreterFeedbackform');
    // }
    if (localStorage.getItem("callSetupid") != null) {
      navigate("/InterpreterFeedbackform");
    }
  };
  useEffect(() => {
    checkredirectpage();
    if (localStorage.getItem("accessToken")) {
      navigate("/home");
    }
  }, []);
  return (
    <Grid
      container
      className="login-container"
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        style={{ whiteSpace: "nowrap", textAlign: "center", marginTop: "40px" }}
      >
        <Vector style={{ width: "120px", height: "110px" }} />

        <div>
          <span className={classes.signText}>Sign</span>
          <span className={classes.ableText}>Able</span>
        </div>
      </Grid>
      <Grid className="header-login">
        <div className="login-text-top">
          <span className="login-text">Login</span>
        </div>
        <form onSubmit={FormData.handleSubmit}>
          <Grid
            item
            sx={10}
            style={{ marginTop: "15px", marginLeft: "4%" }}
            className="phone-input-container"
          >
            <Input
              label="Phone or Email*"
              id="mobile_number"
              className="input-box"
              placeholder="Enter your Phone number or Email"
              value={FormData.values.mobile_number}
              onChange={FormData.handleChange}
              onKeyUp={(e) => displaybtn(e)}
              error={Boolean(
                FormData.touched.mobile_number && FormData.errors.mobile_number
              )}
              helperText={
                FormData.touched.mobile_number && FormData.errors.mobile_number
              }
              onBlur={FormData.handleBlur}
            />
          </Grid>

          <Grid className={classes.button}>
            <Button
              variant="filled"
              type="submit"
              disabled={!is_show}
              className={is_show ? classes.btn_nt_blur : classes.btn_blur}
              style={{
                color: "white",
                width: "325px",
                borderRadius: "8px",
                height: "43px",
                backgroundColor: "#2760A3",
                marginTop: "4%",
              }}
            >
              Get OTP
            </Button>
          </Grid>
          {showerror ? (
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3%",
              }}
            >
              <span className="Responseerr">{errormessage}</span>
            </Grid>
          ) : (
            ""
          )}
          <Grid></Grid>
        </form>
      </Grid>

      <Grid style={{ marginTop: "40px" }} className="register_links">
        <Typography>Do you have a SignAble account?</Typography>
        <NavLink
          style={{
            alignContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          to="/register"
        >
          {" "}
          Register
        </NavLink>
      </Grid>
      {isOpen && <Otp mobile_number={FormData.values.mobile_number} />}
    </Grid>
  );
}
