import React,{useState} from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from "../../Assets/call-a-person-search-img.svg";
import Contact_img from "../../Assets/call-a-person-contact-img.svg";
import Contact_img2 from "../../Assets/call-a-person-contact-img2.svg";
import Contact_img3 from "../../Assets/call-a-person-contact-img3.svg";
import Conference_user from "../../Assets/conference-user.svg";
import Conference_user_remove from "../../Assets/conference-user-remove.svg";
import Two_user from "../../Assets/two_user.svg";
import {ReactComponent as Icon_whitevideocall} from "../../Assets/white-video-call.svg";
import {ReactComponent as Icon_teluguicon} from "../../Assets/telugu-icon.svg";
import {getprofile,getAuth} from '../../Service/Helper';

const ViewSelectedTabs = (props) => {
	
	if(props.tabvalue==0){
		
		return (
			<div className="call-person-tab-content">
				<div className="call-person-tab-content-inner">
					<div className="call-person-tab-header">
						<h3>Start A Conference</h3>
					</div>
					{/* <div className="call-person-search-form">
						<form >
							<label>
								<img src={SearchIcon}/>
								<input
								type="text"
								name="search-contacts"
								className="search-contacts"
								id="search-contacts"
								placeholder="Search from contacts, groups and calls"
								/>
							</label>
							<input type="submit" id="search-contacts-btn"/>
						</form>
					</div> */}
					<div className="call-person-bottom-title">
						<p><img src={Two_user} /> {props.selectedmember.member.length} people added</p>
					</div>
					{props.selectedmember.contactdetails.map(function(contactdetails,i){
						return (
						<div className="call-person-bottom-content">
						<div className="call-person-details">
						<div className="call-person-img">
								<p className="single_profinitial">{getprofile(contactdetails.contact_name,'')}</p>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>{contactdetails.contact_name}</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							
							<div className="call-person-video-call">
								<a href="javascript:" onClick={() => props.doselectedmember(contactdetails,i,'ViewSelectedTabs') } className={props.selectedmember.contactdetails.includes(contactdetails._id)?'Icon_member_added' : 'Icon_member'} >
									<img src={Conference_user_remove}/>
								</a>
							</div>
							
						</div>
					</div>
					
							)
					})}
					<Button className="add-more-conference" onClick={()=>props.setSelected(false)}>Add More</Button>
					
					{/* <div className="form-div language-detected">
					  <span className="detect">Language detected</span><span className="language"><Icon_teluguicon />Telugu</span><span className="change">Change</span>
					</div> */}
					<div className="form-div cb-div text-align-left inte">
													<input 
													type="checkbox" 
													id="need-interpreter" 
													name="need-interpreter" 
													className="need-interpreter"
													value="need-interpreter" /><span className="checkmark"></span>Need interpreter of same gender
												</div>
					<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={()=>props.setProccessed(true)} ><Icon_whitevideocall /> Proceed to call</Button>
				</div>
			</div>
		)
	}
	else if(props.tabvalue==1){
		//const [inputs, setInputs] = React({});
		/*const handleChange = (event) => {
			const name = event.target.name;
    		const value = event.target.value;
    		setInputs(values => ({...values, [name]: value}))
		}
		const handleSubmit = (event) => {
			event.preventDefault();
			console.log();
		}*/
		return (
			<div className="call-person-tab-content">
				<div className="call-person-tab-content-inner">
					<div className="call-person-tab-header">
						<h3>Start A Conference</h3>
					</div>
					<div className="call-person-search-form">
						<form /*onSubmit={handleSubmit}*/>
							<label>
								<img src={SearchIcon}/>
								<input
								type="text"
								name="search-contacts"
								className="search-contacts"
								id="search-contacts"
								placeholder="Search from contacts, groups and calls"
								/*value={inputs.search_contact || ""}
								onChange={handleChange}*//>
							</label>
							<input type="submit" id="search-contacts-btn"/>
						</form>
					</div>
					<div className="call-person-bottom-title">
						<p><img src={Two_user} /> 2 people added</p>
					</div>
					<div className="call-person-bottom-content">
						<div className="call-person-details">
							<div className="call-person-img">
								<img src={Contact_img}/>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>Alice Henery</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:">
									<img src={Conference_user_remove}/>
								</a>
							</div>
						</div>
					</div>
					<div className="call-person-bottom-content">
						<div className="call-person-details">
							<div className="call-person-img">
								<img src={Contact_img2}/>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>Erika Barrite</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:">
									<img src={Conference_user_remove}/>
								</a>
							</div>
						</div>
					</div>
					<div className="form-div language-detected">
					  <span className="detect">Language detected</span><span className="language"><Icon_teluguicon />Telugu</span><span className="change">Change</span>
					</div>
					<div className="form-div cb-div text-align-left inte">
													<input 
													type="checkbox" 
													id="need-interpreter" 
													name="need-interpreter" 
													className="need-interpreter"
													value="need-interpreter" /><span className="checkmark"></span>Need interpreter of same gender
												</div>
					<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={()=>props.setProccessed(true)} ><Icon_whitevideocall /> Proceed to call</Button>
				</div>
			</div>
		)
	}
	else if(props.tabvalue==2){
		//const [inputs, setInputs] = React({});
		/*const handleChange = (event) => {
			const name = event.target.name;
    		const value = event.target.value;
    		setInputs(values => ({...values, [name]: value}))
		}
		const handleSubmit = (event) => {
			event.preventDefault();
			console.log();
		}*/
		return (
			<div className="call-person-tab-content">
				<div className="call-person-tab-content-inner">
					<div className="call-person-tab-header">
						<h3>Start A Conference</h3>
					</div>
					<div className="call-person-search-form">
						<form /*onSubmit={handleSubmit}*/>
							<label>
								<img src={SearchIcon}/>
								<input
								type="text"
								name="search-contacts"
								className="search-contacts"
								id="search-contacts"
								placeholder="Search from contacts, groups and calls"
								/*value={inputs.search_contact || ""}
								onChange={handleChange}*//>
							</label>
							<input type="submit" id="search-contacts-btn"/>
						</form>
					</div>
					<div className="call-person-bottom-title">
						<p><img src={Two_user} /> 2 people added</p>
					</div>
					<div className="call-person-bottom-content">
						<div className="call-person-details">
							<div className="call-person-img">
								<img src={Contact_img}/>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>Alice Henery</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:">
									<img src={Conference_user_remove}/>
								</a>
							</div>
						</div>
					</div>
					<div className="call-person-bottom-content">
						<div className="call-person-details">
							<div className="call-person-img">
								<img src={Contact_img2}/>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>Erika Barrite</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:">
									<img src={Conference_user_remove}/>
								</a>
							</div>
						</div>
					</div>
					<div className="form-div language-detected">
					  <span className="detect">Language detected</span><span className="language"><Icon_teluguicon />Telugu</span><span className="change">Change</span>
					</div>
					<div className="form-div cb-div text-align-left inte">
													<input 
													type="checkbox" 
													id="need-interpreter" 
													name="need-interpreter" 
													className="need-interpreter"
													value="need-interpreter" /><span className="checkmark"></span>Need interpreter of same gender
												</div>
					<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={()=>props.setProccessed(true)} ><Icon_whitevideocall /> Proceed to call</Button>
				</div>
			</div>
		)
	}
	else if(props.tabvalue==3){
		//const [inputs, setInputs] = React({});
		/*const handleChange = (event) => {
			const name = event.target.name;
    		const value = event.target.value;
    		setInputs(values => ({...values, [name]: value}))
		}
		const handleSubmit = (event) => {
			event.preventDefault();
			console.log();
		}*/
		return (
			<div className="call-person-tab-content">
				<div className="call-person-tab-content-inner">
					<div className="call-person-tab-header">
						<h3>Start A Conference</h3>
					</div>
					<div className="call-person-search-form">
						<form /*onSubmit={handleSubmit}*/>
							<label>
								<img src={SearchIcon}/>
								<input
								type="text"
								name="search-contacts"
								className="search-contacts"
								id="search-contacts"
								placeholder="Search from contacts, groups and calls"
								/*value={inputs.search_contact || ""}
								onChange={handleChange}*//>
							</label>
							<input type="submit" id="search-contacts-btn"/>
						</form>
					</div>
					<div className="call-person-bottom-title">
						<p><img src={Two_user} /> 2 people added</p>
					</div>
					<div className="call-person-bottom-content">
						<div className="call-person-details">
							<div className="call-person-img">
								<img src={Contact_img}/>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>Alice Henery</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:">
									<img src={Conference_user_remove}/>
								</a>
							</div>
						</div>
					</div>
					<div className="call-person-bottom-content">
						<div className="call-person-details">
							<div className="call-person-img">
								<img src={Contact_img2}/>
							</div>
							<div className="call-person-inner-details">
								<div className="call-person-name">
									<p>Erika Barrite</p>
								</div>
								<div className="call-person-status">
									<p>Hey i am ready!</p>
								</div>
							</div>
							<div className="call-person-video-call">
								<a href="javascript:">
									<img src={Conference_user_remove}/>
								</a>
							</div>
						</div>
					</div>
					<div className="form-div language-detected">
					  <span className="detect">Language detected</span><span className="language"><Icon_teluguicon />Telugu</span><span className="change">Change</span>
					</div>
					<div className="form-div cb-div text-align-left inte">
													<input 
													type="checkbox" 
													id="need-interpreter" 
													name="need-interpreter" 
													className="need-interpreter"
													value="need-interpreter" /><span className="checkmark"></span>Need interpreter of same gender
												</div>
					<Button className="primary" variant="text"  style={{ marginTop: "4%" }} onClick={()=>props.setProccessed(true)} ><Icon_whitevideocall /> Proceed to call</Button>
				</div>
			</div>
		)
	}
		
}

export default ViewSelectedTabs;